import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import { RoleType } from '@goatsports/core/data-access';

export const RoleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const currentUserService = inject(CurrentUserService);
  const router = inject(Router);
  const userRole = currentUserService.currentUserRole;

  if (
    userRole === RoleType.Translator &&
    state.url.startsWith('/configure/translation')
  ) {
    return true;
  }

  if (state.url.startsWith('/configure/translation')) {
    router.navigate(['/']);
    return false;
  }

  if (
    userRole === RoleType.Coach ||
    userRole === RoleType.OrganizationAdmin ||
    userRole === RoleType.SuperAdmin
  ) {
    return true;
  }

  userRole === RoleType.Translator
    ? router.navigate(['/configure/translation'])
    : router.navigate(['/']);
  return false;
};
