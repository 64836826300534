{
  "main": {
    "sr-Latn": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "sr",
        "script": "Latn"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "jan",
                  "2": "feb",
                  "3": "mar",
                  "4": "apr",
                  "5": "maj",
                  "6": "jun",
                  "7": "jul",
                  "8": "avg",
                  "9": "sep",
                  "10": "okt",
                  "11": "nov",
                  "12": "dec"
                },
                "narrow": {
                  "1": "j",
                  "2": "f",
                  "3": "m",
                  "4": "a",
                  "5": "m",
                  "6": "j",
                  "7": "j",
                  "8": "a",
                  "9": "s",
                  "10": "o",
                  "11": "n",
                  "12": "d"
                },
                "wide": {
                  "1": "januar",
                  "2": "februar",
                  "3": "mart",
                  "4": "april",
                  "5": "maj",
                  "6": "jun",
                  "7": "jul",
                  "8": "avgust",
                  "9": "septembar",
                  "10": "oktobar",
                  "11": "novembar",
                  "12": "decembar"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "jan",
                  "2": "feb",
                  "3": "mar",
                  "4": "apr",
                  "5": "maj",
                  "6": "jun",
                  "7": "jul",
                  "8": "avg",
                  "9": "sep",
                  "10": "okt",
                  "11": "nov",
                  "12": "dec"
                },
                "narrow": {
                  "1": "j",
                  "2": "f",
                  "3": "m",
                  "4": "a",
                  "5": "m",
                  "6": "j",
                  "7": "j",
                  "8": "a",
                  "9": "s",
                  "10": "o",
                  "11": "n",
                  "12": "d"
                },
                "wide": {
                  "1": "januar",
                  "2": "februar",
                  "3": "mart",
                  "4": "april",
                  "5": "maj",
                  "6": "jun",
                  "7": "jul",
                  "8": "avgust",
                  "9": "septembar",
                  "10": "oktobar",
                  "11": "novembar",
                  "12": "decembar"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "ned",
                  "mon": "pon",
                  "tue": "uto",
                  "wed": "sre",
                  "thu": "čet",
                  "fri": "pet",
                  "sat": "sub"
                },
                "narrow": {
                  "sun": "n",
                  "mon": "p",
                  "tue": "u",
                  "wed": "s",
                  "thu": "č",
                  "fri": "p",
                  "sat": "s"
                },
                "short": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "ut",
                  "wed": "sr",
                  "thu": "če",
                  "fri": "pe",
                  "sat": "su"
                },
                "wide": {
                  "sun": "nedelja",
                  "mon": "ponedeljak",
                  "tue": "utorak",
                  "wed": "sreda",
                  "thu": "četvrtak",
                  "fri": "petak",
                  "sat": "subota"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "ned",
                  "mon": "pon",
                  "tue": "uto",
                  "wed": "sre",
                  "thu": "čet",
                  "fri": "pet",
                  "sat": "sub"
                },
                "narrow": {
                  "sun": "n",
                  "mon": "p",
                  "tue": "u",
                  "wed": "s",
                  "thu": "č",
                  "fri": "p",
                  "sat": "s"
                },
                "short": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "ut",
                  "wed": "sr",
                  "thu": "če",
                  "fri": "pe",
                  "sat": "su"
                },
                "wide": {
                  "sun": "nedelja",
                  "mon": "ponedeljak",
                  "tue": "utorak",
                  "wed": "sreda",
                  "thu": "četvrtak",
                  "fri": "petak",
                  "sat": "subota"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "K1",
                  "2": "K2",
                  "3": "K3",
                  "4": "K4"
                },
                "narrow": {
                  "1": "1.",
                  "2": "2.",
                  "3": "3.",
                  "4": "4."
                },
                "wide": {
                  "1": "prvi kvartal",
                  "2": "drugi kvartal",
                  "3": "treći kvartal",
                  "4": "četvrti kvartal"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "K1",
                  "2": "K2",
                  "3": "K3",
                  "4": "K4"
                },
                "narrow": {
                  "1": "1.",
                  "2": "2.",
                  "3": "3.",
                  "4": "4."
                },
                "wide": {
                  "1": "prvi kvartal",
                  "2": "drugi kvartal",
                  "3": "treći kvartal",
                  "4": "četvrti kvartal"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "ponoć",
                  "am": "pre podne",
                  "noon": "podne",
                  "pm": "po podne",
                  "morning1": "ujutro",
                  "afternoon1": "po podne",
                  "evening1": "uveče",
                  "night1": "noću"
                },
                "narrow": {
                  "midnight": "ponoć",
                  "am": "a",
                  "noon": "podne",
                  "pm": "p",
                  "morning1": "ujutru",
                  "afternoon1": "po podne",
                  "evening1": "uveče",
                  "night1": "noću"
                },
                "wide": {
                  "midnight": "ponoć",
                  "am": "pre podne",
                  "noon": "podne",
                  "pm": "po podne",
                  "morning1": "ujutro",
                  "afternoon1": "po podne",
                  "evening1": "uveče",
                  "night1": "noću"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "ponoć",
                  "am": "pre podne",
                  "noon": "podne",
                  "pm": "po podne",
                  "morning1": "jutro",
                  "afternoon1": "popodne",
                  "evening1": "veče",
                  "night1": "noć"
                },
                "narrow": {
                  "midnight": "ponoć",
                  "am": "pre podne",
                  "noon": "podne",
                  "pm": "po podne",
                  "morning1": "jutro",
                  "afternoon1": "popodne",
                  "evening1": "veče",
                  "night1": "noć"
                },
                "wide": {
                  "midnight": "ponoć",
                  "am": "pre podne",
                  "noon": "podne",
                  "pm": "po podne",
                  "morning1": "jutro",
                  "afternoon1": "popodne",
                  "evening1": "veče",
                  "night1": "noć"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "pre nove ere",
                "0-alt-variant": "p. n. e.",
                "1": "nove ere",
                "1-alt-variant": "n. e."
              },
              "eraAbbr": {
                "0": "p. n. e.",
                "0-alt-variant": "p. n. e.",
                "1": "n. e.",
                "1-alt-variant": "n. e."
              },
              "eraNarrow": {
                "0": "p.n.e.",
                "0-alt-variant": "p. n. e.",
                "1": "n.e.",
                "1-alt-variant": "n. e."
              }
            },
            "dateFormats": {
              "full": "EEEE, dd. MMMM y.",
              "long": "dd. MMMM y.",
              "medium": "dd.MM.y.",
              "short": "d.M.yy."
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} {0}",
              "long": "{1} {0}",
              "medium": "{1} {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "hh:mm B",
                "Bhms": "hh:mm:ss B",
                "d": "d",
                "E": "E",
                "EBhm": "E hh:mm B",
                "EBhms": "E hh:mm:ss B",
                "Ed": "E d.",
                "Ehm": "E h:mm a",
                "EHm": "E HH:mm",
                "Ehms": "E h:mm:ss a",
                "EHms": "E HH:mm:ss",
                "Gy": "y. G",
                "GyMMM": "MMM y. G",
                "GyMMMd": "d. MMM y. G",
                "GyMMMEd": "E, d. MMM y. G",
                "h": "h a",
                "H": "HH",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "L",
                "Md": "d.M.",
                "MEd": "E, d.M.",
                "MMdd": "dd.MM.",
                "MMM": "LLL",
                "MMMd": "d. MMM",
                "MMMdd": "dd.MMM",
                "MMMEd": "E d. MMM",
                "MMMMd": "d. MMMM",
                "MMMMEd": "E, d. MMMM",
                "MMMMW-count-one": "W. 'sedmica' 'u' MMMM.",
                "MMMMW-count-few": "W. 'sedmica' 'u' MMMM.",
                "MMMMW-count-other": "W. 'sedmica' 'u' MMMM.",
                "ms": "mm:ss",
                "y": "y.",
                "yM": "M.y.",
                "yMd": "d.M.y.",
                "yMEd": "E, d.M.y.",
                "yMM": "MM.y.",
                "yMMdd": "dd.MM.y.",
                "yMMM": "MMM y.",
                "yMMMd": "d. MMM y.",
                "yMMMEd": "E, d. MMM y.",
                "yMMMM": "MMMM y.",
                "yQQQ": "QQQ y.",
                "yQQQQ": "QQQQ y.",
                "yw-count-one": "w. 'sedmica' 'u' Y.",
                "yw-count-few": "w. 'sedmica' 'u' Y.",
                "yw-count-other": "w. 'sedmica' 'u' Y."
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h–h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm–h:mm B",
                  "m": "h:mm–h:mm B"
                },
                "d": {
                  "d": "d–d"
                },
                "Gy": {
                  "G": "G y – G y",
                  "y": "G y–y"
                },
                "GyM": {
                  "G": "GGGGG y-MM – GGGGG y-MM",
                  "M": "GGGGG y-MM – y-MM",
                  "y": "GGGGG y-MM – y-MM"
                },
                "GyMd": {
                  "d": "GGGGG y-MM-dd – y-MM-dd",
                  "G": "GGGGG y-MM-dd – GGGGG y-MM-dd",
                  "M": "GGGGG y-MM-dd – y-MM-dd",
                  "y": "GGGGG y-MM-dd – y-MM-dd"
                },
                "GyMEd": {
                  "d": "GGGGG y-MM-dd, E – y-MM-dd, E",
                  "G": "GGGGG y-MM-dd, E – GGGGG y-MM-dd, E",
                  "M": "GGGGG y-MM-dd, E – y-MM-dd, E",
                  "y": "GGGGG y-MM-dd, E – y-MM-dd, E"
                },
                "GyMMM": {
                  "G": "G y MMM – G y MMM",
                  "M": "G y MMM–MMM",
                  "y": "G y MMM – y MMM"
                },
                "GyMMMd": {
                  "d": "G y MMM d–d",
                  "G": "G y MMM d – G y MMM d",
                  "M": "G y MMM d – MMM d",
                  "y": "G y MMM d – y MMM d"
                },
                "GyMMMEd": {
                  "d": "G y MMM d, E – MMM d, E",
                  "G": "G y MMM d, E – G y MMM d, E",
                  "M": "G y MMM d, E – MMM d, E",
                  "y": "G y MMM d, E – y MMM d, E"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm v",
                  "m": "HH:mm–HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH v"
                },
                "M": {
                  "M": "M–M"
                },
                "Md": {
                  "d": "d.M – d.M",
                  "M": "d.M – d.M"
                },
                "MEd": {
                  "d": "E, d.M – E, d.M",
                  "M": "E, d.M – E, d.M"
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "dd.–dd. MMM",
                  "M": "dd. MMM – dd. MMM"
                },
                "MMMEd": {
                  "d": "E, dd. – E, dd. MMM",
                  "M": "E, dd. MMM – E, dd. MMM"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "M – M, y",
                  "y": "M.y. – M.y."
                },
                "yMd": {
                  "d": "d.M.y. – d.M.y.",
                  "M": "d.M.y. – d.M.y.",
                  "y": "d.M.y. – d.M.y."
                },
                "yMEd": {
                  "d": "E, d.M.y. – E, d.M.y.",
                  "M": "E, d.M.y. – E, d.M.y.",
                  "y": "E, d.M.y. – E, d.M.y."
                },
                "yMMM": {
                  "M": "MMM–MMM y.",
                  "y": "MMM y. – MMM y."
                },
                "yMMMd": {
                  "d": "dd.–dd. MMM y.",
                  "M": "dd. MMM – dd. MMM y.",
                  "y": "dd. MMM y. – dd. MMM y."
                },
                "yMMMEd": {
                  "d": "E, dd. – E, dd. MMM y.",
                  "M": "E, dd. MMM – E, dd. MMM y.",
                  "y": "E, dd. MMM y. – E, dd. MMM y."
                },
                "yMMMM": {
                  "M": "MMMM – MMMM y.",
                  "y": "MMMM y. – MMMM y."
                }
              }
            }
          }
        }
      }
    }
  }
}
