import { CanDeactivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HasUnsavedChanges } from '../models/has-unsaved-changes';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { translate } from '@jsverse/transloco';

export const unsavedChangesGuard: CanDeactivateFn<HasUnsavedChanges> = (
  component,
) => {
  if (component.isSubmitted()) {
    return of(true);
  }
  if (component.hasUnsavedChanges()) {
    return new Observable<boolean>((observer) => {
      const dialogRef = DialogUtility.confirm({
        title: translate('Exit confirmation'),
        content: translate(
          'You have unsaved changes. If you leave now, those changes will be lost. Do you want to leave without saving?',
        ),
        isModal: true,
        showCloseIcon: true,
        closeOnEscape: true,
        position: {},
        width: 465,
        okButton: {
          text: translate('Leave'),
          cssClass: 'e-btn custom-btn',
          click: () => {
            dialogRef.close(true);
            observer.next(true);
            observer.complete();
          },
        },
        cancelButton: {
          text: translate('Cancel'),
          cssClass: 'e-btn custom-cancel-btn',
          click: () => {
            dialogRef.close(false);
            observer.next(false);
            observer.complete();
          },
        },
      });
    });
  }
  return of(true);
};
