{
  "main": {
    "is": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "is"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0}",
          "regionFormat-type-daylight": "{0} (sumartími)",
          "regionFormat-type-standard": "{0} (staðaltími)",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Angvilla"
              },
              "Antigua": {
                "exemplarCity": "Antígva"
              },
              "Araguaina": {
                "exemplarCity": "Araguaina"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucuman"
                }
              },
              "Aruba": {
                "exemplarCity": "Arúba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Bahia"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahia Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belem"
              },
              "Belize": {
                "exemplarCity": "Belís"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogota"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Kankún"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Cayenne"
              },
              "Cayman": {
                "exemplarCity": "Cayman-eyjar"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Cordoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Kostaríka"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiaba"
              },
              "Curacao": {
                "exemplarCity": "Curacao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dóminíka"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "El Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Gvadelúp"
              },
              "Guatemala": {
                "exemplarCity": "Gvatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Gvæjana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamaíka"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceio"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martiník"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlan"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Merida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Mexíkóborg"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "New York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, North Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, North Dakota"
                },
                "Center": {
                  "exemplarCity": "Center, North Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Púertó Ríkó"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarem"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "Sao Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "Sankti Bartólómeusareyjar"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "Sankti Kitts"
              },
              "St_Lucia": {
                "exemplarCity": "Sankti Lúsía"
              },
              "St_Thomas": {
                "exemplarCity": "Sankti Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "Sankti Vinsent"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Thule"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortóla"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azoreyjar"
              },
              "Bermuda": {
                "exemplarCity": "Bermúda"
              },
              "Canary": {
                "exemplarCity": "Kanaríeyjar"
              },
              "Cape_Verde": {
                "exemplarCity": "Grænhöfðaeyjar"
              },
              "Faeroe": {
                "exemplarCity": "Færeyjar"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavík"
              },
              "South_Georgia": {
                "exemplarCity": "Suður-Georgía"
              },
              "St_Helena": {
                "exemplarCity": "Sankti Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astrakhan"
              },
              "Athens": {
                "exemplarCity": "Aþena"
              },
              "Belgrade": {
                "exemplarCity": "Belgrad"
              },
              "Berlin": {
                "exemplarCity": "Berlín"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Brussel"
              },
              "Bucharest": {
                "exemplarCity": "Búkarest"
              },
              "Budapest": {
                "exemplarCity": "Búdapest"
              },
              "Busingen": {
                "exemplarCity": "Busingen"
              },
              "Chisinau": {
                "exemplarCity": "Chisinau"
              },
              "Copenhagen": {
                "exemplarCity": "Kaupmannahöfn"
              },
              "Dublin": {
                "long": {
                  "daylight": "Sumartími á Írlandi"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gíbraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinki"
              },
              "Isle_of_Man": {
                "exemplarCity": "Mön"
              },
              "Istanbul": {
                "exemplarCity": "Istanbúl"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kænugarður"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lissabon"
              },
              "Ljubljana": {
                "exemplarCity": "Ljubljana"
              },
              "London": {
                "long": {
                  "daylight": "Sumartími í Bretlandi"
                },
                "exemplarCity": "Lundúnir"
              },
              "Luxembourg": {
                "exemplarCity": "Lúxemborg"
              },
              "Madrid": {
                "exemplarCity": "Madríd"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Maríuhöfn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Mónakó"
              },
              "Moscow": {
                "exemplarCity": "Moskva"
              },
              "Oslo": {
                "exemplarCity": "Osló"
              },
              "Paris": {
                "exemplarCity": "París"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Prag"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Róm"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marínó"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevó"
              },
              "Saratov": {
                "exemplarCity": "Saratov"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sófía"
              },
              "Stockholm": {
                "exemplarCity": "Stokkhólmur"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirane"
              },
              "Ulyanovsk": {
                "exemplarCity": "Ulyanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Uzhgorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vatíkanið"
              },
              "Vienna": {
                "exemplarCity": "Vín"
              },
              "Vilnius": {
                "exemplarCity": "Vilníus"
              },
              "Volgograd": {
                "exemplarCity": "Volgograd"
              },
              "Warsaw": {
                "exemplarCity": "Varsjá"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporozhye"
              },
              "Zurich": {
                "exemplarCity": "Zurich"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidjan"
              },
              "Accra": {
                "exemplarCity": "Accra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Ababa"
              },
              "Algiers": {
                "exemplarCity": "Algeirsborg"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissá"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Kaíró"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Djibútí"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Aaiun"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Jóhannesarborg"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Khartoum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lome"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadishu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndjamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nouakchott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto-Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "Saó Tóme"
              },
              "Tripoli": {
                "exemplarCity": "Trípólí"
              },
              "Tunis": {
                "exemplarCity": "Túnisborg"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Almaty"
              },
              "Amman": {
                "exemplarCity": "Amman"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aqtau"
              },
              "Aqtobe": {
                "exemplarCity": "Aqtobe"
              },
              "Ashgabat": {
                "exemplarCity": "Ashgabat"
              },
              "Atyrau": {
                "exemplarCity": "Atyrau"
              },
              "Baghdad": {
                "exemplarCity": "Bagdad"
              },
              "Bahrain": {
                "exemplarCity": "Barein"
              },
              "Baku": {
                "exemplarCity": "Bakú"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Beirút"
              },
              "Bishkek": {
                "exemplarCity": "Bishkek"
              },
              "Brunei": {
                "exemplarCity": "Brúnei"
              },
              "Calcutta": {
                "exemplarCity": "Kalkútta"
              },
              "Chita": {
                "exemplarCity": "Chita"
              },
              "Choibalsan": {
                "exemplarCity": "Choibalsan"
              },
              "Colombo": {
                "exemplarCity": "Kólombó"
              },
              "Damascus": {
                "exemplarCity": "Damaskus"
              },
              "Dhaka": {
                "exemplarCity": "Dakka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Dushanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hong Kong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkutsk"
              },
              "Jakarta": {
                "exemplarCity": "Djakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerúsalem"
              },
              "Kabul": {
                "exemplarCity": "Kabúl"
              },
              "Kamchatka": {
                "exemplarCity": "Kamtsjatka"
              },
              "Karachi": {
                "exemplarCity": "Karachi"
              },
              "Katmandu": {
                "exemplarCity": "Katmandú"
              },
              "Khandyga": {
                "exemplarCity": "Khandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnoyarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kúala Lúmpúr"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Kúveit"
              },
              "Macau": {
                "exemplarCity": "Makaó"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Muscat"
              },
              "Nicosia": {
                "exemplarCity": "Níkósía"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznetsk"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pjongjang"
              },
              "Qatar": {
                "exemplarCity": "Katar"
              },
              "Qostanay": {
                "exemplarCity": "Kostanay"
              },
              "Qyzylorda": {
                "exemplarCity": "Qyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangún"
              },
              "Riyadh": {
                "exemplarCity": "Ríjad"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minh-borg"
              },
              "Sakhalin": {
                "exemplarCity": "Sakhalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Seúl"
              },
              "Shanghai": {
                "exemplarCity": "Sjanghæ"
              },
              "Singapore": {
                "exemplarCity": "Singapúr"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednekolymsk"
              },
              "Taipei": {
                "exemplarCity": "Taipei"
              },
              "Tashkent": {
                "exemplarCity": "Tashkent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teheran"
              },
              "Thimphu": {
                "exemplarCity": "Thimphu"
              },
              "Tokyo": {
                "exemplarCity": "Tókýó"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Úlan Bator"
              },
              "Urumqi": {
                "exemplarCity": "Urumqi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Yakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Yekaterinburg"
              },
              "Yerevan": {
                "exemplarCity": "Yerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagos"
              },
              "Christmas": {
                "exemplarCity": "Jólaey"
              },
              "Cocos": {
                "exemplarCity": "Kókoseyjar"
              },
              "Comoro": {
                "exemplarCity": "Comoro"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelen"
              },
              "Mahe": {
                "exemplarCity": "Mahe"
              },
              "Maldives": {
                "exemplarCity": "Maldíveyjar"
              },
              "Mauritius": {
                "exemplarCity": "Máritíus"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Páskaeyja"
              },
              "Efate": {
                "exemplarCity": "Efate"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fidjí"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapagos"
              },
              "Gambier": {
                "exemplarCity": "Gambier"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Gvam"
              },
              "Honolulu": {
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Marquesas-eyjar"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nárú"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Noumea"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palá"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairn"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahítí"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "Samræmdur alþjóðlegur tími"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "Óþekkt borg"
              }
            }
          },
          "metazone": {
            "Afghanistan": {
              "long": {
                "standard": "Afganistantími"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Mið-Afríkutími"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "Austur-Afríkutími"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "Suður-Afríkutími"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "Vestur-Afríkutími",
                "standard": "Staðaltími í Vestur-Afríku",
                "daylight": "Sumartími í Vestur-Afríku"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Tími í Alaska",
                "standard": "Staðaltími í Alaska",
                "daylight": "Sumartími í Alaska"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amasóntími",
                "standard": "Staðaltími á Amasónsvæðinu",
                "daylight": "Sumartími á Amasónsvæðinu"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Tími í miðhluta Bandaríkjanna og Kanada",
                "standard": "Staðaltími í miðhluta Bandaríkjanna og Kanada",
                "daylight": "Sumartími í miðhluta Bandaríkjanna og Kanada"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Tími í austurhluta Bandaríkjanna og Kanada",
                "standard": "Staðaltími í austurhluta Bandaríkjanna og Kanada",
                "daylight": "Sumartími í austurhluta Bandaríkjanna og Kanada"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Tími í Klettafjöllum",
                "standard": "Staðaltími í Klettafjöllum",
                "daylight": "Sumartími í Klettafjöllum"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Tími á Kyrrahafssvæðinu",
                "standard": "Staðaltími á Kyrrahafssvæðinu",
                "daylight": "Sumartími á Kyrrahafssvæðinu"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Tími í Anadyr",
                "standard": "Staðaltími í Anadyr",
                "daylight": "Sumartími í Anadyr"
              }
            },
            "Apia": {
              "long": {
                "generic": "Tími í Apía",
                "standard": "Staðaltími í Apía",
                "daylight": "Sumartími í Apía"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Arabíutími",
                "standard": "Staðaltími í Arabíu",
                "daylight": "Sumartími í Arabíu"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentínutími",
                "standard": "Staðaltími í Argentínu",
                "daylight": "Sumartími í Argentínu"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Vestur-Argentínutími",
                "standard": "Staðaltími í Vestur-Argentínu",
                "daylight": "Sumartími í Vestur-Argentínu"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Armeníutími",
                "standard": "Staðaltími í Armeníu",
                "daylight": "Sumartími í Armeníu"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Tími á Atlantshafssvæðinu",
                "standard": "Staðaltími á Atlantshafssvæðinu",
                "daylight": "Sumartími á Atlantshafssvæðinu"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Tími í Mið-Ástralíu",
                "standard": "Staðaltími í Mið-Ástralíu",
                "daylight": "Sumartími í Mið-Ástralíu"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Tími í miðvesturhluta Ástralíu",
                "standard": "Staðaltími í miðvesturhluta Ástralíu",
                "daylight": "Sumartími í miðvesturhluta Ástralíu"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Tími í Austur-Ástralíu",
                "standard": "Staðaltími í Austur-Ástralíu",
                "daylight": "Sumartími í Austur-Ástralíu"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "Tími í Vestur-Ástralíu",
                "standard": "Staðaltími í Vestur-Ástralíu",
                "daylight": "Sumartími í Vestur-Ástralíu"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Aserbaídsjantími",
                "standard": "Staðaltími í Aserbaídsjan",
                "daylight": "Sumartími í Aserbaídsjan"
              }
            },
            "Azores": {
              "long": {
                "generic": "Asóreyjatími",
                "standard": "Staðaltími á Asóreyjum",
                "daylight": "Sumartími á Asóreyjum"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bangladess-tími",
                "standard": "Staðaltími í Bangladess",
                "daylight": "Sumartími í Bangladess"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Bútantími"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Bólivíutími"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brasilíutími",
                "standard": "Staðaltími í Brasilíu",
                "daylight": "Sumartími í Brasilíu"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Brúneitími"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Grænhöfðaeyjatími",
                "standard": "Staðaltími á Grænhöfðaeyjum",
                "daylight": "Sumartími á Grænhöfðaeyjum"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Chamorro-staðaltími"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chatham-tími",
                "standard": "Staðaltími í Chatham",
                "daylight": "Sumartími í Chatham"
              }
            },
            "Chile": {
              "long": {
                "generic": "Síletími",
                "standard": "Staðaltími í Síle",
                "daylight": "Sumartími í Síle"
              }
            },
            "China": {
              "long": {
                "generic": "Kínatími",
                "standard": "Staðaltími í Kína",
                "daylight": "Sumartími í Kína"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Tími í Choibalsan",
                "standard": "Staðaltími í Choibalsan",
                "daylight": "Sumartími í Choibalsan"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Jólaeyjartími"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Kókoseyjatími"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Kólumbíutími",
                "standard": "Staðaltími í Kólumbíu",
                "daylight": "Sumartími í Kólumbíu"
              }
            },
            "Cook": {
              "long": {
                "generic": "Cooks-eyjatími",
                "standard": "Staðaltími á Cooks-eyjum",
                "daylight": "Hálfsumartími á Cooks-eyjum"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Kúbutími",
                "standard": "Staðaltími á Kúbu",
                "daylight": "Sumartími á Kúbu"
              }
            },
            "Davis": {
              "long": {
                "standard": "Davis-tími"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Tími á Dumont-d’Urville"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Tíminn á Tímor-Leste"
              }
            },
            "Easter": {
              "long": {
                "generic": "Páskaeyjutími",
                "standard": "Staðaltími á Páskaeyju",
                "daylight": "Sumartími á Páskaeyju"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ekvadortími"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Mið-Evróputími",
                "standard": "Staðaltími í Mið-Evrópu",
                "daylight": "Sumartími í Mið-Evrópu"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Austur-Evróputími",
                "standard": "Staðaltími í Austur-Evrópu",
                "daylight": "Sumartími í Austur-Evrópu"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Staðartími Kalíníngrad"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "Vestur-Evróputími",
                "standard": "Staðaltími í Vestur-Evrópu",
                "daylight": "Sumartími í Vestur-Evrópu"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falklandseyjatími",
                "standard": "Staðaltími á Falklandseyjum",
                "daylight": "Sumartími á Falklandseyjum"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fídjíeyjatími",
                "standard": "Staðaltími á Fídjíeyjum",
                "daylight": "Sumartími á Fídjíeyjum"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Tími í Frönsku Gvæjana"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Tími á frönsku suðurhafssvæðum og Suðurskautslandssvæði"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapagos-tími"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambier-tími"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Georgíutími",
                "standard": "Staðaltími í Georgíu",
                "daylight": "Sumartími í Georgíu"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Tími á Gilbert-eyjum"
              }
            },
            "GMT": {
              "long": {
                "standard": "Greenwich-staðaltími"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Austur-Grænlandstími",
                "standard": "Staðaltími á Austur-Grænlandi",
                "daylight": "Sumartími á Austur-Grænlandi"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "Vestur-Grænlandstími",
                "standard": "Staðaltími á Vestur-Grænlandi",
                "daylight": "Sumartími á Vestur-Grænlandi"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Staðaltími við Persaflóa"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Gvæjanatími"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Tími á Havaí og Aleúta",
                "standard": "Staðaltími á Havaí og Aleúta",
                "daylight": "Sumartími á Havaí og Aleúta"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hong Kong-tími",
                "standard": "Staðaltími í Hong Kong",
                "daylight": "Sumartími í Hong Kong"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Hovd-tími",
                "standard": "Staðaltími í Hovd",
                "daylight": "Sumartími í Hovd"
              }
            },
            "India": {
              "long": {
                "standard": "Indlandstími"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Indlandshafstími"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Indókínatími"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Mið-Indónesíutími"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Austur-Indónesíutími"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Vestur-Indónesíutími"
              }
            },
            "Iran": {
              "long": {
                "generic": "Íranstími",
                "standard": "Staðaltími í Íran",
                "daylight": "Sumartími í Íran"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Tími í Irkutsk",
                "standard": "Staðaltími í Irkutsk",
                "daylight": "Sumartími í Irkutsk"
              }
            },
            "Israel": {
              "long": {
                "generic": "Ísraelstími",
                "standard": "Staðaltími í Ísrael",
                "daylight": "Sumartími í Ísrael"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japanstími",
                "standard": "Staðaltími í Japan",
                "daylight": "Sumartími í Japan"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Tími í Petropavlovsk-Kamchatski",
                "standard": "Staðaltími í Petropavlovsk-Kamchatski",
                "daylight": "Sumartími í Petropavlovsk-Kamchatski"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Tími í Austur-Kasakstan"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "Tími í Vestur-Kasakstan"
              }
            },
            "Korea": {
              "long": {
                "generic": "Kóreutími",
                "standard": "Staðaltími í Kóreu",
                "daylight": "Sumartími í Kóreu"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosrae-tími"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Tími í Krasnoyarsk",
                "standard": "Staðaltími í Krasnoyarsk",
                "daylight": "Sumartími í Krasnoyarsk"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kirgistan-tími"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Línueyja-tími"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Tími á Lord Howe-eyju",
                "standard": "Staðaltími á Lord Howe-eyju",
                "daylight": "Sumartími á Lord Howe-eyju"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Macquarie-eyjartími"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Tími í Magadan",
                "standard": "Staðaltími í Magadan",
                "daylight": "Sumartími í Magadan"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Malasíutími"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maldíveyja-tími"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Tími á Markgreifafrúreyjum"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Tími á Marshall-eyjum"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Máritíustími",
                "standard": "Staðaltími á Máritíus",
                "daylight": "Sumartími á Máritíus"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Mawson-tími"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Tími í Norðvestur-Mexíkó",
                "standard": "Staðaltími í Norðvestur-Mexíkó",
                "daylight": "Sumartími í Norðvestur-Mexíkó"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Kyrrahafstími í Mexíkó",
                "standard": "Staðaltími í Mexíkó á Kyrrahafssvæðinu",
                "daylight": "Sumartími í Mexíkó á Kyrrahafssvæðinu"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Tími í Úlan Bator",
                "standard": "Staðaltími í Úlan Bator",
                "daylight": "Sumartími í Úlan Bator"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskvutími",
                "standard": "Staðaltími í Moskvu",
                "daylight": "Sumartími í Moskvu"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Mjanmar-tími"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Nárú-tími"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepaltími"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Tími í Nýju-Kaledóníu",
                "standard": "Staðaltími í Nýju-Kaledóníu",
                "daylight": "Sumartími í Nýju-Kaledóníu"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Tími á Nýja-Sjálandi",
                "standard": "Staðaltími á Nýja-Sjálandi",
                "daylight": "Sumartími á Nýja-Sjálandi"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Tími á Nýfundnalandi",
                "standard": "Staðaltími á Nýfundnalandi",
                "daylight": "Sumartími á Nýfundnalandi"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niue-tími"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Tími á Norfolk-eyju"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Tími í Fernando de Noronha",
                "standard": "Staðaltími í Fernando de Noronha",
                "daylight": "Sumartími í Fernando de Noronha"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Tími í Novosibirsk",
                "standard": "Staðaltími í Novosibirsk",
                "daylight": "Sumartími í Novosibirsk"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Tíminn í Omsk",
                "standard": "Staðaltími í Omsk",
                "daylight": "Sumartími í Omsk"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pakistantími",
                "standard": "Staðaltími í Pakistan",
                "daylight": "Sumartími í Pakistan"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palátími"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Tími á Papúa Nýju-Gíneu"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paragvætími",
                "standard": "Staðaltími í Paragvæ",
                "daylight": "Sumartími í Paragvæ"
              }
            },
            "Peru": {
              "long": {
                "generic": "Perútími",
                "standard": "Staðaltími í Perú",
                "daylight": "Sumartími í Perú"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Filippseyjatími",
                "standard": "Staðaltími á Filippseyjum",
                "daylight": "Sumartími á Filippseyjum"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Fönixeyjatími"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Tími á Sankti Pierre og Miquelon",
                "standard": "Staðaltími á Sankti Pierre og Miquelon",
                "daylight": "Sumartími á Sankti Pierre og Miquelon"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitcairn-tími"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Ponape-tími"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Tími í Pjongjang"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Réunion-tími"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rothera-tími"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Tími í Sakhalin",
                "standard": "Staðaltími í Sakhalin",
                "daylight": "Sumartími í Sakhalin"
              }
            },
            "Samara": {
              "long": {
                "generic": "Tími í Samara",
                "standard": "Staðaltími í Samara",
                "daylight": "Sumartími í Samara"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samóa-tími",
                "standard": "Staðaltími á Samóa",
                "daylight": "Sumartími á Samóa"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seychelles-eyjatími"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singapúrtími"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Salómonseyjatími"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Suður-Georgíutími"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Súrinamtími"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Syowa-tími"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahítí-tími"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Taipei-tími",
                "standard": "Staðaltími í Taipei",
                "daylight": "Sumartími í Taipei"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tadsjíkistan-tími"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tókelá-tími"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tongatími",
                "standard": "Staðaltími á Tonga",
                "daylight": "Sumartími á Tonga"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuuk-tími"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Túrkmenistan-tími",
                "standard": "Staðaltími í Túrkmenistan",
                "daylight": "Sumartími í Túrkmenistan"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Túvalútími"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Úrúgvætími",
                "standard": "Staðaltími í Úrúgvæ",
                "daylight": "Sumartími í Úrúgvæ"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Úsbekistan-tími",
                "standard": "Staðaltími í Úsbekistan",
                "daylight": "Sumartími í Úsbekistan"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanúatú-tími",
                "standard": "Staðaltími á Vanúatú",
                "daylight": "Sumartími á Vanúatú"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Venesúelatími"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Tími í Vladivostok",
                "standard": "Staðaltími í Vladivostok",
                "daylight": "Sumartími í Vladivostok"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Tími í Volgograd",
                "standard": "Staðaltími í Volgograd",
                "daylight": "Sumartími í Volgograd"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Vostok-tími"
              }
            },
            "Wake": {
              "long": {
                "standard": "Tími á Wake-eyju"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Tími á Wallis- og Fútúnaeyjum"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Tíminn í Yakutsk",
                "standard": "Staðaltími í Yakutsk",
                "daylight": "Sumartími í Yakutsk"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Tími í Yekaterinburg",
                "standard": "Staðaltími í Yekaterinborg",
                "daylight": "Sumartími í Yekaterinburg"
              }
            }
          }
        }
      }
    }
  }
}
