{
  "main": {
    "is": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "is"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "jan.",
                  "2": "feb.",
                  "3": "mar.",
                  "4": "apr.",
                  "5": "maí",
                  "6": "jún.",
                  "7": "júl.",
                  "8": "ágú.",
                  "9": "sep.",
                  "10": "okt.",
                  "11": "nóv.",
                  "12": "des."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "Á",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "janúar",
                  "2": "febrúar",
                  "3": "mars",
                  "4": "apríl",
                  "5": "maí",
                  "6": "júní",
                  "7": "júlí",
                  "8": "ágúst",
                  "9": "september",
                  "10": "október",
                  "11": "nóvember",
                  "12": "desember"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "jan.",
                  "2": "feb.",
                  "3": "mar.",
                  "4": "apr.",
                  "5": "maí",
                  "6": "jún.",
                  "7": "júl.",
                  "8": "ágú.",
                  "9": "sep.",
                  "10": "okt.",
                  "11": "nóv.",
                  "12": "des."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "Á",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "janúar",
                  "2": "febrúar",
                  "3": "mars",
                  "4": "apríl",
                  "5": "maí",
                  "6": "júní",
                  "7": "júlí",
                  "8": "ágúst",
                  "9": "september",
                  "10": "október",
                  "11": "nóvember",
                  "12": "desember"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "sun.",
                  "mon": "mán.",
                  "tue": "þri.",
                  "wed": "mið.",
                  "thu": "fim.",
                  "fri": "fös.",
                  "sat": "lau."
                },
                "narrow": {
                  "sun": "S",
                  "mon": "M",
                  "tue": "Þ",
                  "wed": "M",
                  "thu": "F",
                  "fri": "F",
                  "sat": "L"
                },
                "short": {
                  "sun": "su.",
                  "mon": "má.",
                  "tue": "þr.",
                  "wed": "mi.",
                  "thu": "fi.",
                  "fri": "fö.",
                  "sat": "la."
                },
                "wide": {
                  "sun": "sunnudagur",
                  "mon": "mánudagur",
                  "tue": "þriðjudagur",
                  "wed": "miðvikudagur",
                  "thu": "fimmtudagur",
                  "fri": "föstudagur",
                  "sat": "laugardagur"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "sun.",
                  "mon": "mán.",
                  "tue": "þri.",
                  "wed": "mið.",
                  "thu": "fim.",
                  "fri": "fös.",
                  "sat": "lau."
                },
                "narrow": {
                  "sun": "S",
                  "mon": "M",
                  "tue": "Þ",
                  "wed": "M",
                  "thu": "F",
                  "fri": "F",
                  "sat": "L"
                },
                "short": {
                  "sun": "su.",
                  "mon": "má.",
                  "tue": "þr.",
                  "wed": "mi.",
                  "thu": "fi.",
                  "fri": "fö.",
                  "sat": "la."
                },
                "wide": {
                  "sun": "sunnudagur",
                  "mon": "mánudagur",
                  "tue": "þriðjudagur",
                  "wed": "miðvikudagur",
                  "thu": "fimmtudagur",
                  "fri": "föstudagur",
                  "sat": "laugardagur"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "F1",
                  "2": "F2",
                  "3": "F3",
                  "4": "F4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. fjórðungur",
                  "2": "2. fjórðungur",
                  "3": "3. fjórðungur",
                  "4": "4. fjórðungur"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "F1",
                  "2": "F2",
                  "3": "F3",
                  "4": "F4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. fjórðungur",
                  "2": "2. fjórðungur",
                  "3": "3. fjórðungur",
                  "4": "4. fjórðungur"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "miðnætti",
                  "am": "f.h.",
                  "noon": "hádegi",
                  "pm": "e.h.",
                  "morning1": "að morgni",
                  "afternoon1": "síðdegis",
                  "evening1": "að kvöldi",
                  "night1": "að nóttu"
                },
                "narrow": {
                  "midnight": "mn.",
                  "am": "f.",
                  "noon": "h.",
                  "pm": "e.",
                  "morning1": "mrg.",
                  "afternoon1": "sd.",
                  "evening1": "kv.",
                  "night1": "n."
                },
                "wide": {
                  "midnight": "miðnætti",
                  "am": "f.h.",
                  "noon": "hádegi",
                  "pm": "e.h.",
                  "morning1": "að morgni",
                  "afternoon1": "síðdegis",
                  "evening1": "að kvöldi",
                  "night1": "að nóttu"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "miðnætti",
                  "am": "f.h.",
                  "noon": "hádegi",
                  "pm": "e.h.",
                  "morning1": "morgunn",
                  "afternoon1": "síðdegis",
                  "evening1": "kvöld",
                  "night1": "nótt"
                },
                "narrow": {
                  "midnight": "mn.",
                  "am": "f.h.",
                  "noon": "hd.",
                  "pm": "e.h.",
                  "morning1": "mrg.",
                  "afternoon1": "sd.",
                  "evening1": "kv.",
                  "night1": "n."
                },
                "wide": {
                  "midnight": "miðnætti",
                  "am": "f.h.",
                  "noon": "hádegi",
                  "pm": "e.h.",
                  "morning1": "morgunn",
                  "afternoon1": "eftir hádegi",
                  "evening1": "kvöld",
                  "night1": "nótt"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "fyrir Krist",
                "0-alt-variant": "fyrir kristið tímatal",
                "1": "eftir Krist",
                "1-alt-variant": "kristið tímatal"
              },
              "eraAbbr": {
                "0": "f.Kr.",
                "0-alt-variant": "f.l.t.",
                "1": "e.Kr.",
                "1-alt-variant": "l.t."
              },
              "eraNarrow": {
                "0": "f.k.",
                "0-alt-variant": "f.v.t.",
                "1": "e.k.",
                "1-alt-variant": "v.t."
              }
            },
            "dateFormats": {
              "full": "EEEE, d. MMMM y",
              "long": "d. MMMM y",
              "medium": "d. MMM y",
              "short": "d.M.y"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} 'kl'. {0}",
              "long": "{1} 'kl'. {0}",
              "medium": "{1}, {0}",
              "short": "{1}, {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d",
                "E": "ccc",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E d.",
                "Ehm": "E, h:mm a",
                "EHm": "E, HH:mm",
                "Ehms": "E, h:mm:ss a",
                "EHms": "E, HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d. MMM y G",
                "GyMMMEd": "E, d. MMM y G",
                "h": "h a",
                "H": "HH",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "v – HH:mm:ss",
                "hmv": "h:mm a v",
                "Hmv": "v – HH:mm",
                "M": "L",
                "Md": "d.M.",
                "MEd": "E, d.M.",
                "MMM": "LLL",
                "MMMd": "d. MMM",
                "MMMEd": "E, d. MMM",
                "MMMMd": "d. MMMM",
                "MMMMEd": "E, d. MMMM",
                "MMMMW-count-one": "'viku' W 'í' MMMM",
                "MMMMW-count-other": "'viku' W 'í' MMMM",
                "ms": "mm:ss",
                "y": "y",
                "yM": "M. y",
                "yMd": "d.M.y",
                "yMEd": "E, d.M.y",
                "yMMM": "MMM y",
                "yMMMd": "d. MMM y",
                "yMMMEd": "E, d. MMM y",
                "yMMMM": "MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "'viku' w 'af' Y",
                "yw-count-other": "'viku' w 'af' Y"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h–h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm–h:mm B",
                  "m": "h:mm–h:mm B"
                },
                "d": {
                  "d": "d.–d."
                },
                "Gy": {
                  "G": "y G – y G",
                  "y": "y–y G"
                },
                "GyM": {
                  "G": "MM.y GGGGG – MM.y GGGGG",
                  "M": "MM.y – MM.y GGGGG",
                  "y": "MM.y – MM.y GGGGG"
                },
                "GyMd": {
                  "d": "dd.MM.y – dd.MM.y GGGGG",
                  "G": "dd.MM.y GGGGG – dd.MM.y GGGGG",
                  "M": "dd.MM.y – dd.MM.y GGGGG",
                  "y": "dd.MM.y – dd.MM.y GGGGG"
                },
                "GyMEd": {
                  "d": "E, dd.MM.y – E, dd.MM.y GGGGG",
                  "G": "E, dd.MM.y GGGGG – E, dd.MM.y GGGGG",
                  "M": "E, dd.MM.y – E, dd.MM.y GGGGG",
                  "y": "E, dd.MM.y – E, dd.MM.y GGGGG"
                },
                "GyMMM": {
                  "G": "MMM y G – MMM y G",
                  "M": "MMM–MMM y G",
                  "y": "MMM y – MMM y G"
                },
                "GyMMMd": {
                  "d": "d.–d. MMM y G",
                  "G": "d. MMM y G – d. MMM y G",
                  "M": "d. MMM – d. MMM y G",
                  "y": "d. MMM y – d. MMM y G"
                },
                "GyMMMEd": {
                  "d": "E, d. MMM – E, d. MMM y G",
                  "G": "E, d. MMM y G – E, d. MMM y G",
                  "M": "E, d. MMM – E, d. MMM y G",
                  "y": "E, d. MMM y – E, d. MMM y G"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm v",
                  "m": "HH:mm–HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH v"
                },
                "M": {
                  "M": "M.–M."
                },
                "Md": {
                  "d": "d.M.–d.M.",
                  "M": "d.M.–d.M."
                },
                "MEd": {
                  "d": "E, d.M. – E, d.M.",
                  "M": "E, d.M. – E, d.M."
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "d.–d. MMM",
                  "M": "d. MMM – d. MMM"
                },
                "MMMEd": {
                  "d": "E, d. – E, d. MMM",
                  "M": "E, d. MMM – E, d. MMM"
                },
                "MMMM": {
                  "M": "LLLL–LLLL"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "M.y – M.y",
                  "y": "M.y – M.y"
                },
                "yMd": {
                  "d": "d.M.y – d.M.y",
                  "M": "d.M.y – d.M.y",
                  "y": "d.M.y – d.M.y"
                },
                "yMEd": {
                  "d": "E, d.M.y – E, d.M.y",
                  "M": "E, d.M.y – E, d.M.y",
                  "y": "E, d.M.y – E, d.M.y"
                },
                "yMMM": {
                  "M": "MMM–MMM y",
                  "y": "MMM y – MMM y"
                },
                "yMMMd": {
                  "d": "d.–d. MMM y",
                  "M": "d. MMM – d. MMM y",
                  "y": "d. MMM y – d. MMM y"
                },
                "yMMMEd": {
                  "d": "E, d. – E, d. MMM y",
                  "M": "E, d. MMM – E, d. MMM y",
                  "y": "E, d. MMM y – E, d. MMM y"
                },
                "yMMMM": {
                  "M": "MMMM–MMMM y",
                  "y": "MMMM y – MMMM y"
                }
              }
            }
          }
        }
      }
    }
  }
}
