import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@goatsports/shared/util';
import { RouteConstants } from '../shared/constants/route.constant';
import { UsersService } from '@goatsports/core/data-access';
import { DateTime } from 'luxon';
import { filter, from, of, switchMap, tap } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'goatsports-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent extends BaseComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const shouldLogOut = params.get('logOut') === 'true';
          if (shouldLogOut && this.authenticationService.isUserLoggedIn()) {
            return this.handleLogout();
          } else {
            return this.handleSignIn();
          }
        })
      )
      .subscribe();
  }

  private handleLogout() {
    this.authenticationService.logout();
    return of(null);
  }

  private handleSignIn() {
    return from(
      this.authenticationService.loadDiscoveryDocumentAndLogin()
    ).pipe(
      filter((isAuthenticated) => !!isAuthenticated),
      switchMap(() =>
        this.usersService.usersSignInPost({
          timeZone: DateTime.local().zoneName as string,
        })
      ),
      tap(() => {
        this.router.navigate([`/${RouteConstants.DASHBOARD_ROUTE}`]);
      })
    );
  }
}
