import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import {
  ApiModule as AuthorityApiModule,
  Configuration as AuthorityConfiguration,
} from '@goatsports/authority/data-access';
import {
  ApiModule as CoreApiModule,
  Configuration as CoreConfiguration,
} from '@goatsports/core/data-access';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  TranslationsService,
  TranslocoRootModule,
} from '@goatsports/shared/util';
import { HomeComponent } from './home/home.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { ServerHttpInterceptor } from './shared/interceptors/server-http.interceptor';
import { ToastService } from './shared/services/toast.service';
import { ErrorService } from './shared/services/error.service';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import localeEn from '@angular/common/locales/en';
import localeSr from '@angular/common/locales/sr-Latn';
import localeIs from '@angular/common/locales/is';
import { AuthenticationService } from './shared/services/authentication.service';

registerLocaleData(localeSv);
registerLocaleData(localeEn);
registerLocaleData(localeSr);
registerLocaleData(localeIs);

function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}

function configureAuth(authenticationService: AuthenticationService) {
  return () => authenticationService.configureAuth(environment.authorityApiUrl);
}

function configureTranslations(translationsService: TranslationsService) {
  return () => {
    translationsService.initializeTranslation();
  };
}

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    CoreApiModule.forRoot(
      () =>
        new CoreConfiguration({
          basePath: environment.coreApiUrl,
        }),
    ),
    AuthorityApiModule.forRoot(
      () =>
        new AuthorityConfiguration({
          basePath: environment.authorityApiUrl,
          withCredentials: true,
        }),
    ),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.coreApiUrl],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: OAuthStorage, useFactory: oAuthStorageFactory },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configureTranslations,
      multi: true,
      deps: [TranslationsService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      deps: [ErrorService, AuthenticationService, ToastService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
