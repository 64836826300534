{
  "main": {
    "sv": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "sv"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;−HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0}tid",
          "regionFormat-type-daylight": "{0} (sommartid)",
          "regionFormat-type-standard": "{0} (normaltid)",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antigua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaína"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Río Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucumán"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Bahia"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahía de Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belém"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogotá"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancún"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Cayenne"
              },
              "Cayman": {
                "exemplarCity": "Caymanöarna"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Córdoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Costa Rica"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiabá"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominica"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepé"
              },
              "El_Salvador": {
                "exemplarCity": "El Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Guadeloupe"
              },
              "Guatemala": {
                "exemplarCity": "Guatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Guyana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havanna"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamaica"
              },
              "Jujuy": {
                "exemplarCity": "San Salvador de Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceió"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martinique"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlán"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Mérida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Mexiko City"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "New York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Fernando de Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, North Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, North Dakota"
                },
                "Center": {
                  "exemplarCity": "Center, North Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Puerto Rico"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarém"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "São Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "S:t Barthélemy"
              },
              "St_Johns": {
                "exemplarCity": "S:t Johns"
              },
              "St_Kitts": {
                "exemplarCity": "S:t Kitts"
              },
              "St_Lucia": {
                "exemplarCity": "S:t Lucia"
              },
              "St_Thomas": {
                "exemplarCity": "S:t Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "S:t Vincent"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Qaanaaq"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azorerna"
              },
              "Bermuda": {
                "exemplarCity": "Bermuda"
              },
              "Canary": {
                "exemplarCity": "Kanarieöarna"
              },
              "Cape_Verde": {
                "exemplarCity": "Kap Verde"
              },
              "Faeroe": {
                "exemplarCity": "Torshamn"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavik"
              },
              "South_Georgia": {
                "exemplarCity": "Sydgeorgien"
              },
              "St_Helena": {
                "exemplarCity": "S:t Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astrakhan"
              },
              "Athens": {
                "exemplarCity": "Aten"
              },
              "Belgrade": {
                "exemplarCity": "Belgrad"
              },
              "Berlin": {
                "exemplarCity": "Berlin"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Bryssel"
              },
              "Bucharest": {
                "exemplarCity": "Bukarest"
              },
              "Budapest": {
                "exemplarCity": "Budapest"
              },
              "Busingen": {
                "exemplarCity": "Büsingen am Hochrhein"
              },
              "Chisinau": {
                "exemplarCity": "Chișinău"
              },
              "Copenhagen": {
                "exemplarCity": "Köpenhamn"
              },
              "Dublin": {
                "long": {
                  "daylight": "irländsk sommartid"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsingfors"
              },
              "Isle_of_Man": {
                "exemplarCity": "Isle of Man"
              },
              "Istanbul": {
                "exemplarCity": "Istanbul"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kiev"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lissabon"
              },
              "Ljubljana": {
                "exemplarCity": "Ljubljana"
              },
              "London": {
                "long": {
                  "daylight": "brittisk sommartid"
                },
                "exemplarCity": "London"
              },
              "Luxembourg": {
                "exemplarCity": "Luxemburg"
              },
              "Madrid": {
                "exemplarCity": "Madrid"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Mariehamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Monaco"
              },
              "Moscow": {
                "exemplarCity": "Moskva"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Paris"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Prag"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Rom"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Saratov": {
                "exemplarCity": "Saratov"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sofia"
              },
              "Stockholm": {
                "exemplarCity": "Stockholm"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Uljanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Uzjhorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vatikanen"
              },
              "Vienna": {
                "exemplarCity": "Wien"
              },
              "Vilnius": {
                "exemplarCity": "Vilnius"
              },
              "Volgograd": {
                "exemplarCity": "Volgograd"
              },
              "Warsaw": {
                "exemplarCity": "Warszawa"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporizjzja"
              },
              "Zurich": {
                "exemplarCity": "Zürich"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidjan"
              },
              "Accra": {
                "exemplarCity": "Accra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Abeba"
              },
              "Algiers": {
                "exemplarCity": "Alger"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Kairo"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es-Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Djibouti"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El-Aaiún"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburg"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Khartoum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lomé"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadishu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "N’Djamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nouakchott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto-Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "São Tomé"
              },
              "Tripoli": {
                "exemplarCity": "Tripoli"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Almaty"
              },
              "Amman": {
                "exemplarCity": "Amman"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aktau"
              },
              "Aqtobe": {
                "exemplarCity": "Aqtöbe"
              },
              "Ashgabat": {
                "exemplarCity": "Asjchabad"
              },
              "Atyrau": {
                "exemplarCity": "Atyrau"
              },
              "Baghdad": {
                "exemplarCity": "Bagdad"
              },
              "Bahrain": {
                "exemplarCity": "Bahrain"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Beirut"
              },
              "Bishkek": {
                "exemplarCity": "Bisjkek"
              },
              "Brunei": {
                "exemplarCity": "Brunei"
              },
              "Calcutta": {
                "exemplarCity": "Kolkata"
              },
              "Chita": {
                "exemplarCity": "Tjita"
              },
              "Choibalsan": {
                "exemplarCity": "Tjojbalsan"
              },
              "Colombo": {
                "exemplarCity": "Colombo"
              },
              "Damascus": {
                "exemplarCity": "Damaskus"
              },
              "Dhaka": {
                "exemplarCity": "Dhaka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Dusjanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hongkong"
              },
              "Hovd": {
                "exemplarCity": "Chovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkutsk"
              },
              "Jakarta": {
                "exemplarCity": "Jakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerusalem"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamtjatka"
              },
              "Karachi": {
                "exemplarCity": "Karachi"
              },
              "Katmandu": {
                "exemplarCity": "Katmandu"
              },
              "Khandyga": {
                "exemplarCity": "Chandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnojarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Kuwait"
              },
              "Macau": {
                "exemplarCity": "Macao"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manilla"
              },
              "Muscat": {
                "exemplarCity": "Muskat"
              },
              "Nicosia": {
                "exemplarCity": "Nicosia"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznetsk"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pyongyang"
              },
              "Qatar": {
                "exemplarCity": "Qatar"
              },
              "Qostanay": {
                "exemplarCity": "Kostanaj"
              },
              "Qyzylorda": {
                "exemplarCity": "Qyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangoon"
              },
              "Riyadh": {
                "exemplarCity": "Riyadh"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minh-staden"
              },
              "Sakhalin": {
                "exemplarCity": "Sachalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Söul"
              },
              "Shanghai": {
                "exemplarCity": "Shanghai"
              },
              "Singapore": {
                "exemplarCity": "Singapore"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednekolymsk"
              },
              "Taipei": {
                "exemplarCity": "Taipei"
              },
              "Tashkent": {
                "exemplarCity": "Tasjkent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teheran"
              },
              "Thimphu": {
                "exemplarCity": "Thimphu"
              },
              "Tokyo": {
                "exemplarCity": "Tokyo"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulaanbaatar"
              },
              "Urumqi": {
                "exemplarCity": "Ürümqi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Jakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Jekaterinburg"
              },
              "Yerevan": {
                "exemplarCity": "Jerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagosöarna"
              },
              "Christmas": {
                "exemplarCity": "Julön"
              },
              "Cocos": {
                "exemplarCity": "Kokosöarna"
              },
              "Comoro": {
                "exemplarCity": "Komorerna"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelenöarna"
              },
              "Mahe": {
                "exemplarCity": "Mahé"
              },
              "Maldives": {
                "exemplarCity": "Maldiverna"
              },
              "Mauritius": {
                "exemplarCity": "Mauritius"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Påskön"
              },
              "Efate": {
                "exemplarCity": "Efate"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fiji"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galápagos"
              },
              "Gambier": {
                "exemplarCity": "Gambieröarna"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "short": {
                  "generic": "Honolulutid",
                  "standard": "Honolulunormaltid",
                  "daylight": "Honolulusommartid"
                },
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnstonatollen"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Marquesasöarna"
              },
              "Midway": {
                "exemplarCity": "Midwayöarna"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Nouméa"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairnöarna"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallisön"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "Koordinerad universell tid"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "okänd stad"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "västbrasiliansk tid",
                "standard": "västbrasiliansk normaltid",
                "daylight": "västbrasiliansk sommartid"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "afghansk tid"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "centralafrikansk tid"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "östafrikansk tid"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "sydafrikansk tid"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "västafrikansk tid",
                "standard": "västafrikansk normaltid",
                "daylight": "västafrikansk sommartid"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Alaskatid",
                "standard": "Alaska, normaltid",
                "daylight": "Alaska, sommartid"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Almatytid",
                "standard": "Almatynormaltid",
                "daylight": "Almatysommartid"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amazonastid",
                "standard": "Amazonas, normaltid",
                "daylight": "Amazonas, sommartid"
              }
            },
            "America_Central": {
              "long": {
                "generic": "centralnordamerikansk tid",
                "standard": "centralnordamerikansk normaltid",
                "daylight": "centralnordamerikansk sommartid"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "östnordamerikansk tid",
                "standard": "östnordamerikansk normaltid",
                "daylight": "östnordamerikansk sommartid"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Klippiga bergentid",
                "standard": "Klippiga bergen, normaltid",
                "daylight": "Klippiga bergen, sommartid"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "västnordamerikansk tid",
                "standard": "västnordamerikansk normaltid",
                "daylight": "västnordamerikansk sommartid"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Anadyrtid",
                "standard": "Anadyrnormaltid",
                "daylight": "Anadyrsommartid"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apiatid",
                "standard": "Apia, normaltid",
                "daylight": "Apia, sommartid"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Aqtautid",
                "standard": "Aqtaunormaltid",
                "daylight": "Aqtausommartid"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Aqtöbetid",
                "standard": "Aqtöbenormaltid",
                "daylight": "Aqtöbesommartid"
              }
            },
            "Arabian": {
              "long": {
                "generic": "saudiarabisk tid",
                "standard": "saudiarabisk normaltid",
                "daylight": "saudiarabisk sommartid"
              }
            },
            "Argentina": {
              "long": {
                "generic": "östargentinsk tid",
                "standard": "östargentinsk normaltid",
                "daylight": "östargentinsk sommartid"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "västargentinsk tid",
                "standard": "västargentinsk normaltid",
                "daylight": "västargentinsk sommartid"
              }
            },
            "Armenia": {
              "long": {
                "generic": "armenisk tid",
                "standard": "armenisk normaltid",
                "daylight": "armenisk sommartid"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "nordamerikansk atlanttid",
                "standard": "nordamerikansk atlantnormaltid",
                "daylight": "nordamerikansk atlantsommartid"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "centralaustralisk tid",
                "standard": "centralaustralisk normaltid",
                "daylight": "centralaustralisk sommartid"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "västcentralaustralisk tid",
                "standard": "västcentralaustralisk normaltid",
                "daylight": "västcentralaustralisk sommartid"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "östaustralisk tid",
                "standard": "östaustralisk normaltid",
                "daylight": "östaustralisk sommartid"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "västaustralisk tid",
                "standard": "västaustralisk normaltid",
                "daylight": "västaustralisk sommartid"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "azerbajdzjansk tid",
                "standard": "azerbajdzjansk normaltid",
                "daylight": "azerbajdzjansk sommartid"
              }
            },
            "Azores": {
              "long": {
                "generic": "azorisk tid",
                "standard": "azorisk normaltid",
                "daylight": "azorisk sommartid"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "bangladeshisk tid",
                "standard": "bangladeshisk normaltid",
                "daylight": "bangladeshisk sommartid"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "bhutansk tid"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "boliviansk tid"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brasiliatid",
                "standard": "Brasilia, normaltid",
                "daylight": "Brasilia, sommartid"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Bruneitid"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Kap Verdetid",
                "standard": "Kap Verde, normaltid",
                "daylight": "Kap Verde, sommartid"
              }
            },
            "Casey": {
              "long": {
                "standard": "Caseytid"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Chamorrotid"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chathamtid",
                "standard": "Chatham, normaltid",
                "daylight": "Chatham, sommartid"
              }
            },
            "Chile": {
              "long": {
                "generic": "chilensk tid",
                "standard": "chilensk normaltid",
                "daylight": "chilensk sommartid"
              }
            },
            "China": {
              "long": {
                "generic": "kinesisk tid",
                "standard": "kinesisk normaltid",
                "daylight": "kinesisk sommartid"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Tjojbalsantid",
                "standard": "Tjojbalsan, normaltid",
                "daylight": "Tjojbalsan, sommartid"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Julöns tid"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Keelingöarnas tid"
              }
            },
            "Colombia": {
              "long": {
                "generic": "colombiansk tid",
                "standard": "colombiansk normaltid",
                "daylight": "colombiansk sommartid"
              }
            },
            "Cook": {
              "long": {
                "generic": "Cooköarnas tid",
                "standard": "Cooköarnas normaltid",
                "daylight": "Cooköarnas sommartid"
              }
            },
            "Cuba": {
              "long": {
                "generic": "kubansk tid",
                "standard": "kubansk normaltid",
                "daylight": "kubansk sommartid"
              }
            },
            "Davis": {
              "long": {
                "standard": "Davistid"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Dumont d’Urville-tid"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "östtimorisk tid"
              }
            },
            "Easter": {
              "long": {
                "generic": "Påskötid",
                "standard": "Påskön, normaltid",
                "daylight": "Påskön, sommartid"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "ecuadoriansk tid"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "centraleuropeisk tid",
                "standard": "centraleuropeisk normaltid",
                "daylight": "centraleuropeisk sommartid"
              },
              "short": {
                "generic": "CET",
                "standard": "CET",
                "daylight": "CEST"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "östeuropeisk tid",
                "standard": "östeuropeisk normaltid",
                "daylight": "östeuropeisk sommartid"
              },
              "short": {
                "generic": "EET",
                "standard": "EET",
                "daylight": "EEST"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Kaliningradtid"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "västeuropeisk tid",
                "standard": "västeuropeisk normaltid",
                "daylight": "västeuropeisk sommartid"
              },
              "short": {
                "generic": "WET",
                "standard": "WET",
                "daylight": "WEST"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falklandsöarnas tid",
                "standard": "Falklandsöarna, normaltid",
                "daylight": "Falklandsöarna, sommartid"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fijitid",
                "standard": "Fiji, normaltid",
                "daylight": "Fiji, sommartid"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Franska Guyanatid"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Franska Sydterritoriernas tid"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galápagostid"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambiertid"
              }
            },
            "Georgia": {
              "long": {
                "generic": "georgisk tid",
                "standard": "georgisk normaltid",
                "daylight": "georgisk sommartid"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Kiribatitid"
              }
            },
            "GMT": {
              "long": {
                "standard": "Greenwichtid"
              },
              "short": {
                "standard": "GMT"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "östgrönländsk tid",
                "standard": "östgrönländsk normaltid",
                "daylight": "östgrönländsk sommartid"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "västgrönländsk tid",
                "standard": "västgrönländsk normaltid",
                "daylight": "västgrönländsk sommartid"
              }
            },
            "Guam": {
              "long": {
                "standard": "Guamtid"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Persiska vikentid"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Guyanatid"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Honolulutid",
                "standard": "Honolulu, normaltid",
                "daylight": "Honolulu, sommartid"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hongkongtid",
                "standard": "Hongkong, normaltid",
                "daylight": "Hongkong, sommartid"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Chovdtid",
                "standard": "Chovd, normaltid",
                "daylight": "Chovd, sommartid"
              }
            },
            "India": {
              "long": {
                "standard": "indisk tid"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Brittiska Indiska oceanöarnas tid"
              }
            },
            "Indochina": {
              "long": {
                "standard": "indokinesisk tid"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "centralindonesisk tid"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "östindonesisk tid"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "västindonesisk tid"
              }
            },
            "Iran": {
              "long": {
                "generic": "iransk tid",
                "standard": "iransk normaltid",
                "daylight": "iransk sommartid"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkutsktid",
                "standard": "Irkutsk, normaltid",
                "daylight": "Irkutsk, sommartid"
              }
            },
            "Israel": {
              "long": {
                "generic": "israelisk tid",
                "standard": "israelisk normaltid",
                "daylight": "israelisk sommartid"
              }
            },
            "Japan": {
              "long": {
                "generic": "japansk tid",
                "standard": "japansk normaltid",
                "daylight": "japansk sommartid"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Kamtjatkatid",
                "standard": "Kamtjatkanormaltid",
                "daylight": "Kamtjatkasommartid"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "östkazakstansk tid"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "västkazakstansk tid"
              }
            },
            "Korea": {
              "long": {
                "generic": "koreansk tid",
                "standard": "koreansk normaltid",
                "daylight": "koreansk sommartid"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosraetid"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnojarsktid",
                "standard": "Krasnojarsk, normaltid",
                "daylight": "Krasnojarsk, sommartid"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "kirgizisk tid"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Sri Lankatid"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Lineöarnas tid"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Lord Howetid",
                "standard": "Lord Howe, normaltid",
                "daylight": "Lord Howe, sommartid"
              }
            },
            "Macau": {
              "long": {
                "generic": "Macaotid",
                "standard": "Macaonormaltid",
                "daylight": "Macaosommartid"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Macquarietid"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadantid",
                "standard": "Magadan, normaltid",
                "daylight": "Magadan, sommartid"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "malaysisk tid"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maldivernatid"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Marquesastid"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Marshallöarnas tid"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauritiustid",
                "standard": "Mauritius, normaltid",
                "daylight": "Mauritius, sommartid"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Mawsontid"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "nordvästmexikansk tid",
                "standard": "nordvästmexikansk normaltid",
                "daylight": "nordvästmexikansk sommartid"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "mexikansk stillahavstid",
                "standard": "mexikansk stillahavstid, normaltid",
                "daylight": "mexikansk stillahavstid, sommartid"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ulaanbaatartid",
                "standard": "Ulaanbaatar, normaltid",
                "daylight": "Ulaanbaatar, sommartid"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskvatid",
                "standard": "Moskva, normaltid",
                "daylight": "Moskva, sommartid"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "burmesisk tid"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Naurutid"
              }
            },
            "Nepal": {
              "long": {
                "standard": "nepalesisk tid"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Nya Kaledonientid",
                "standard": "Nya Kaledonien, normaltid",
                "daylight": "Nya Kaledonien, sommartid"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "nyzeeländsk tid",
                "standard": "nyzeeländsk normaltid",
                "daylight": "nyzeeländsk sommartid"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Newfoundlandtid",
                "standard": "Newfoundland, normaltid",
                "daylight": "Newfoundland, sommartid"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niuetid"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolköns tid"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Fernando de Noronhatid",
                "standard": "Fernando de Noronha, normaltid",
                "daylight": "Fernando de Noronha, sommartid"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "Nordmarianernas tid"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Novosibirsktid",
                "standard": "Novosibirsk, normaltid",
                "daylight": "Novosibirsk, sommartid"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omsktid",
                "standard": "Omsk, normaltid",
                "daylight": "Omsk, sommartid"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "pakistansk tid",
                "standard": "pakistansk normaltid",
                "daylight": "pakistansk sommartid"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palautid"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Papua Nya Guineas tid"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "paraguayansk tid",
                "standard": "paraguayansk normaltid",
                "daylight": "paraguayansk sommartid"
              }
            },
            "Peru": {
              "long": {
                "generic": "peruansk tid",
                "standard": "peruansk normaltid",
                "daylight": "peruansk sommartid"
              }
            },
            "Philippines": {
              "long": {
                "generic": "filippinsk tid",
                "standard": "filippinsk normaltid",
                "daylight": "filippinsk sommartid"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Enderburytid"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "S:t Pierre och Miquelontid",
                "standard": "S:t Pierre och Miquelon, normaltid",
                "daylight": "S:t Pierre och Miquelon, sommartid"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitcairntid"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Ponapetid"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pyongyangtid"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Qyzylordatid",
                "standard": "Qyzylordanormaltid",
                "daylight": "Qyzylordasommartid"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Réuniontid"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rotheratid"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sachalintid",
                "standard": "Sachalin, normaltid",
                "daylight": "Sachalin, sommartid"
              }
            },
            "Samara": {
              "long": {
                "generic": "Samaratid",
                "standard": "Samaranormaltid",
                "daylight": "Samarasommartid"
              }
            },
            "Samoa": {
              "long": {
                "generic": "samoansk tid",
                "standard": "samoansk normaltid",
                "daylight": "samoansk sommartid"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seychellernatid"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singaporetid"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Salomonöarnas tid"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "sydgeorgisk tid"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Surinamtid"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Syowatid"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahititid"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Taipeitid",
                "standard": "Taipei, normaltid",
                "daylight": "Taipei, sommartid"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tadzjikistantid"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelautid"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tongatid",
                "standard": "Tonga, normaltid",
                "daylight": "Tonga, sommartid"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuuktid"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "turkmensk tid",
                "standard": "turkmensk normaltid",
                "daylight": "turkmensk sommartid"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvalutid"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "uruguayansk tid",
                "standard": "uruguayansk normaltid",
                "daylight": "uruguayansk sommartid"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "uzbekisk tid",
                "standard": "uzbekisk normaltid",
                "daylight": "uzbekisk sommartid"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatutid",
                "standard": "Vanuatu, normaltid",
                "daylight": "Vanuatu, sommartid"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "venezuelansk tid"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Vladivostoktid",
                "standard": "Vladivostok, normaltid",
                "daylight": "Vladivostok, sommartid"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Volgogradtid",
                "standard": "Volgograd, normaltid",
                "daylight": "Volgograd, sommartid"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Vostoktid"
              }
            },
            "Wake": {
              "long": {
                "standard": "Wakeöarnas tid"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Wallis- och Futunaöarnas tid"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Jakutsktid",
                "standard": "Jakutsk, normaltid",
                "daylight": "Jakutsk, sommartid"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Jekaterinburgtid",
                "standard": "Jekaterinburg, normaltid",
                "daylight": "Jekaterinburg, sommartid"
              }
            }
          }
        }
      }
    }
  }
}
