{
  "main": {
    "sr-Latn": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "sr",
        "script": "Latn"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0}",
          "regionFormat-type-daylight": "{0}, letnje vreme",
          "regionFormat-type-standard": "{0}, standardno vreme",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Enkoridž"
              },
              "Anguilla": {
                "exemplarCity": "Angvila"
              },
              "Antigua": {
                "exemplarCity": "Antigva"
              },
              "Araguaina": {
                "exemplarCity": "Aragvajana"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Galjegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Huan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ušuaija"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioha"
                },
                "San_Luis": {
                  "exemplarCity": "San Lui"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tukuman"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunsion"
              },
              "Bahia": {
                "exemplarCity": "Baija"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Baija Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belem"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blank-Sejblon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogota"
              },
              "Boise": {
                "exemplarCity": "Bojzi"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Ajres"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Kembridž Bej"
              },
              "Campo_Grande": {
                "exemplarCity": "Kampo Grande"
              },
              "Cancun": {
                "exemplarCity": "Kankun"
              },
              "Caracas": {
                "exemplarCity": "Karakas"
              },
              "Catamarca": {
                "exemplarCity": "Katamarka"
              },
              "Cayenne": {
                "exemplarCity": "Kajen"
              },
              "Cayman": {
                "exemplarCity": "Kajmanska Ostrva"
              },
              "Chicago": {
                "exemplarCity": "Čikago"
              },
              "Chihuahua": {
                "exemplarCity": "Čihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Koral Harbur"
              },
              "Cordoba": {
                "exemplarCity": "Kordoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Kostarika"
              },
              "Creston": {
                "exemplarCity": "Kreston"
              },
              "Cuiaba": {
                "exemplarCity": "Kuiaba"
              },
              "Curacao": {
                "exemplarCity": "Kurasao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshagen"
              },
              "Dawson": {
                "exemplarCity": "Doson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Doson Krik"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominika"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glejs Bej"
              },
              "Godthab": {
                "exemplarCity": "Gothab"
              },
              "Goose_Bay": {
                "exemplarCity": "Gus Bej"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Gvadalupe"
              },
              "Guatemala": {
                "exemplarCity": "Gvatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Gvajakil"
              },
              "Guyana": {
                "exemplarCity": "Gvajana"
              },
              "Halifax": {
                "exemplarCity": "Halifaks"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosiljo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincenes, Indijana"
                },
                "Petersburg": {
                  "exemplarCity": "Pitersburg, Indijana"
                },
                "Tell_City": {
                  "exemplarCity": "Tel Siti, Indijana"
                },
                "Knox": {
                  "exemplarCity": "Noks, Indijana"
                },
                "Winamac": {
                  "exemplarCity": "Vinamak, Indijana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indijana"
                },
                "Vevay": {
                  "exemplarCity": "Vevaj, Indijana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Ikvaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamajka"
              },
              "Jujuy": {
                "exemplarCity": "Žužui"
              },
              "Juneau": {
                "exemplarCity": "Žuno"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Montičelo, Kentaki"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendajk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Anđeles"
              },
              "Louisville": {
                "exemplarCity": "Luivile"
              },
              "Lower_Princes": {
                "exemplarCity": "Louer Prinsiz Kvorter"
              },
              "Maceio": {
                "exemplarCity": "Masejo"
              },
              "Managua": {
                "exemplarCity": "Managva"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigo"
              },
              "Martinique": {
                "exemplarCity": "Martinik"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlan"
              },
              "Mendoza": {
                "exemplarCity": "Mendosa"
              },
              "Menominee": {
                "exemplarCity": "Menomini"
              },
              "Merida": {
                "exemplarCity": "Merida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Meksiko Siti"
              },
              "Miquelon": {
                "exemplarCity": "Mikelon"
              },
              "Moncton": {
                "exemplarCity": "Monkton"
              },
              "Monterrey": {
                "exemplarCity": "Monterej"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserat"
              },
              "Nassau": {
                "exemplarCity": "Nasau"
              },
              "New_York": {
                "exemplarCity": "Njujork"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nom"
              },
              "Noronha": {
                "exemplarCity": "Noronja"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Bijula, Severna Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "Novi Salem, Severna Dakota"
                },
                "Center": {
                  "exemplarCity": "Centar, Severna Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ohinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Finiks"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port o Prens"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spejn"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Veljo"
              },
              "Puerto_Rico": {
                "exemplarCity": "Porto Riko"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rejni River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Resife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolut"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branko"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Izabel"
              },
              "Santarem": {
                "exemplarCity": "Santarem"
              },
              "Santiago": {
                "exemplarCity": "Santjago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "Sao Paolo"
              },
              "Scoresbysund": {
                "exemplarCity": "Skorezbisund"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "Sv. Bartolomej"
              },
              "St_Johns": {
                "exemplarCity": "Sv. Džon"
              },
              "St_Kitts": {
                "exemplarCity": "Sent Kits"
              },
              "St_Lucia": {
                "exemplarCity": "Sv. Lucija"
              },
              "St_Thomas": {
                "exemplarCity": "Sv. Toma"
              },
              "St_Vincent": {
                "exemplarCity": "Sent Vinsent"
              },
              "Swift_Current": {
                "exemplarCity": "Svift Kurent"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegusigalpa"
              },
              "Thule": {
                "exemplarCity": "Tul"
              },
              "Thunder_Bay": {
                "exemplarCity": "Tander Bej"
              },
              "Tijuana": {
                "exemplarCity": "Tihuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vankuver"
              },
              "Whitehorse": {
                "exemplarCity": "Vajthors"
              },
              "Winnipeg": {
                "exemplarCity": "Vinipeg"
              },
              "Yakutat": {
                "exemplarCity": "Jakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Jelounajf"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azori"
              },
              "Bermuda": {
                "exemplarCity": "Bermuda"
              },
              "Canary": {
                "exemplarCity": "Kanarska ostrva"
              },
              "Cape_Verde": {
                "exemplarCity": "Zelenortska Ostrva"
              },
              "Faeroe": {
                "exemplarCity": "Farska Ostrva"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Rejkjavik"
              },
              "South_Georgia": {
                "exemplarCity": "Južna Džordžija"
              },
              "St_Helena": {
                "exemplarCity": "Sveta Jelena"
              },
              "Stanley": {
                "exemplarCity": "Stenli"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andora"
              },
              "Astrakhan": {
                "exemplarCity": "Astrakan"
              },
              "Athens": {
                "exemplarCity": "Atina"
              },
              "Belgrade": {
                "exemplarCity": "Beograd"
              },
              "Berlin": {
                "exemplarCity": "Berlin"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Brisel"
              },
              "Bucharest": {
                "exemplarCity": "Bukurešt"
              },
              "Budapest": {
                "exemplarCity": "Budimpešta"
              },
              "Busingen": {
                "exemplarCity": "Bisingen"
              },
              "Chisinau": {
                "exemplarCity": "Kišinjev"
              },
              "Copenhagen": {
                "exemplarCity": "Kopenhagen"
              },
              "Dublin": {
                "long": {
                  "daylight": "Irska, standardno vreme"
                },
                "exemplarCity": "Dablin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Gernzi"
              },
              "Helsinki": {
                "exemplarCity": "Helsinki"
              },
              "Isle_of_Man": {
                "exemplarCity": "Ostrvo Man"
              },
              "Istanbul": {
                "exemplarCity": "Istanbul"
              },
              "Jersey": {
                "exemplarCity": "Džersi"
              },
              "Kaliningrad": {
                "exemplarCity": "Kalinjingrad"
              },
              "Kiev": {
                "exemplarCity": "Kijev"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lisabon"
              },
              "Ljubljana": {
                "exemplarCity": "Ljubljana"
              },
              "London": {
                "long": {
                  "daylight": "Britanija, letnje vreme"
                },
                "exemplarCity": "London"
              },
              "Luxembourg": {
                "exemplarCity": "Luksemburg"
              },
              "Madrid": {
                "exemplarCity": "Madrid"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Marihamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Monako"
              },
              "Moscow": {
                "exemplarCity": "Moskva"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Pariz"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Prag"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Rim"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Saratov": {
                "exemplarCity": "Saratov"
              },
              "Simferopol": {
                "exemplarCity": "Simferopolj"
              },
              "Skopje": {
                "exemplarCity": "Skoplje"
              },
              "Sofia": {
                "exemplarCity": "Sofija"
              },
              "Stockholm": {
                "exemplarCity": "Stokholm"
              },
              "Tallinn": {
                "exemplarCity": "Talin"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Uljanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Užgorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vatikan"
              },
              "Vienna": {
                "exemplarCity": "Beč"
              },
              "Vilnius": {
                "exemplarCity": "Vilnjus"
              },
              "Volgograd": {
                "exemplarCity": "Volgograd"
              },
              "Warsaw": {
                "exemplarCity": "Varšava"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporožje"
              },
              "Zurich": {
                "exemplarCity": "Cirih"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidžan"
              },
              "Accra": {
                "exemplarCity": "Akra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Adis Abeba"
              },
              "Algiers": {
                "exemplarCity": "Alžir"
              },
              "Asmera": {
                "exemplarCity": "Asmera"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banžul"
              },
              "Bissau": {
                "exemplarCity": "Bisao"
              },
              "Blantyre": {
                "exemplarCity": "Blantir"
              },
              "Brazzaville": {
                "exemplarCity": "Brazavil"
              },
              "Bujumbura": {
                "exemplarCity": "Budžumbura"
              },
              "Cairo": {
                "exemplarCity": "Kairo"
              },
              "Casablanca": {
                "exemplarCity": "Kazablanka"
              },
              "Ceuta": {
                "exemplarCity": "Seuta"
              },
              "Conakry": {
                "exemplarCity": "Konakri"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar-es-Salam"
              },
              "Djibouti": {
                "exemplarCity": "Džibuti"
              },
              "Douala": {
                "exemplarCity": "Duala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Ajun"
              },
              "Freetown": {
                "exemplarCity": "Fritaun"
              },
              "Gaborone": {
                "exemplarCity": "Gaboron"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johanesburg"
              },
              "Juba": {
                "exemplarCity": "Džuba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Kartum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinšasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Librevil"
              },
              "Lome": {
                "exemplarCity": "Lome"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbaši"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadiš"
              },
              "Monrovia": {
                "exemplarCity": "Monrovija"
              },
              "Nairobi": {
                "exemplarCity": "Najrobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndžamena"
              },
              "Niamey": {
                "exemplarCity": "Nijamej"
              },
              "Nouakchott": {
                "exemplarCity": "Nuakšot"
              },
              "Ouagadougou": {
                "exemplarCity": "Uagadugu"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "Sao Tome"
              },
              "Tripoli": {
                "exemplarCity": "Tripoli"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Vindhuk"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Almati"
              },
              "Amman": {
                "exemplarCity": "Aman"
              },
              "Anadyr": {
                "exemplarCity": "Anadir"
              },
              "Aqtau": {
                "exemplarCity": "Aktau"
              },
              "Aqtobe": {
                "exemplarCity": "Akutobe"
              },
              "Ashgabat": {
                "exemplarCity": "Ašhabad"
              },
              "Atyrau": {
                "exemplarCity": "Atirau"
              },
              "Baghdad": {
                "exemplarCity": "Bagdad"
              },
              "Bahrain": {
                "exemplarCity": "Bahrein"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Bejrut"
              },
              "Bishkek": {
                "exemplarCity": "Biškek"
              },
              "Brunei": {
                "exemplarCity": "Brunej"
              },
              "Calcutta": {
                "exemplarCity": "Kalkuta"
              },
              "Chita": {
                "exemplarCity": "Čita"
              },
              "Choibalsan": {
                "exemplarCity": "Čojbalsan"
              },
              "Colombo": {
                "exemplarCity": "Kolombo"
              },
              "Damascus": {
                "exemplarCity": "Damask"
              },
              "Dhaka": {
                "exemplarCity": "Daka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Dušanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hongkong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkuck"
              },
              "Jakarta": {
                "exemplarCity": "Džakarta"
              },
              "Jayapura": {
                "exemplarCity": "Džajapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerusalim"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamčatka"
              },
              "Karachi": {
                "exemplarCity": "Karači"
              },
              "Katmandu": {
                "exemplarCity": "Katmandu"
              },
              "Khandyga": {
                "exemplarCity": "Handiga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnojarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kučing"
              },
              "Kuwait": {
                "exemplarCity": "Kuvajt"
              },
              "Macau": {
                "exemplarCity": "Makao"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makasar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Muskat"
              },
              "Nicosia": {
                "exemplarCity": "Nikozija"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznjeck"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Pnom Pen"
              },
              "Pontianak": {
                "exemplarCity": "Pontijanak"
              },
              "Pyongyang": {
                "exemplarCity": "Pjongjang"
              },
              "Qatar": {
                "exemplarCity": "Katar"
              },
              "Qostanay": {
                "exemplarCity": "Kostanaj"
              },
              "Qyzylorda": {
                "exemplarCity": "Kizilorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangun"
              },
              "Riyadh": {
                "exemplarCity": "Rijad"
              },
              "Saigon": {
                "exemplarCity": "Ho Ši Min"
              },
              "Sakhalin": {
                "exemplarCity": "Sahalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Seul"
              },
              "Shanghai": {
                "exemplarCity": "Šangaj"
              },
              "Singapore": {
                "exemplarCity": "Singapur"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednjekolimsk"
              },
              "Taipei": {
                "exemplarCity": "Tajpej"
              },
              "Tashkent": {
                "exemplarCity": "Taškent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teheran"
              },
              "Thimphu": {
                "exemplarCity": "Timpu"
              },
              "Tokyo": {
                "exemplarCity": "Tokio"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulan Bator"
              },
              "Urumqi": {
                "exemplarCity": "Urumći"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vijentijan"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Jakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Jekaterinburg"
              },
              "Yerevan": {
                "exemplarCity": "Jerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Čagos"
              },
              "Christmas": {
                "exemplarCity": "Božić"
              },
              "Cocos": {
                "exemplarCity": "Kokos"
              },
              "Comoro": {
                "exemplarCity": "Komoro"
              },
              "Kerguelen": {
                "exemplarCity": "Kergelen"
              },
              "Mahe": {
                "exemplarCity": "Mahe"
              },
              "Maldives": {
                "exemplarCity": "Maldivi"
              },
              "Mauritius": {
                "exemplarCity": "Mauricijus"
              },
              "Mayotte": {
                "exemplarCity": "Majot"
              },
              "Reunion": {
                "exemplarCity": "Reunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelejd"
              },
              "Brisbane": {
                "exemplarCity": "Brizbejn"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hil"
              },
              "Currie": {
                "exemplarCity": "Kari"
              },
              "Darwin": {
                "exemplarCity": "Darvin"
              },
              "Eucla": {
                "exemplarCity": "Iukla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Hau"
              },
              "Melbourne": {
                "exemplarCity": "Melburn"
              },
              "Perth": {
                "exemplarCity": "Pert"
              },
              "Sydney": {
                "exemplarCity": "Sidnej"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apija"
              },
              "Auckland": {
                "exemplarCity": "Okland"
              },
              "Bougainville": {
                "exemplarCity": "Buganvil"
              },
              "Chatham": {
                "exemplarCity": "Čatam"
              },
              "Easter": {
                "exemplarCity": "Uskršnje ostrvo"
              },
              "Efate": {
                "exemplarCity": "Efat"
              },
              "Enderbury": {
                "exemplarCity": "Enderberi"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fidži"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapagos"
              },
              "Gambier": {
                "exemplarCity": "Gambije"
              },
              "Guadalcanal": {
                "exemplarCity": "Gvadalkanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Džonston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Košre"
              },
              "Kwajalein": {
                "exemplarCity": "Kvadžalejin"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Markiz"
              },
              "Midway": {
                "exemplarCity": "Midvej"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Numea"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitkern"
              },
              "Ponape": {
                "exemplarCity": "Ponape"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Morzbi"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Sajpan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarava"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Truk"
              },
              "Wake": {
                "exemplarCity": "Vejk"
              },
              "Wallis": {
                "exemplarCity": "Valis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longjerbjen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Kejsi"
              },
              "Davis": {
                "exemplarCity": "Dejvis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dimon d’Urvil"
              },
              "Macquarie": {
                "exemplarCity": "Mekvori"
              },
              "Mawson": {
                "exemplarCity": "Moson"
              },
              "McMurdo": {
                "exemplarCity": "Makmurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rotera"
              },
              "Syowa": {
                "exemplarCity": "Šova"
              },
              "Troll": {
                "exemplarCity": "Trol"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "Koordinisano univerzalno vreme"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "Nepoznat grad"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "Akre vreme",
                "standard": "Akre standardno vreme",
                "daylight": "Akre letnje računanje vremena"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "Avganistan vreme"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Centralno-afričko vreme"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "Istočno-afričko vreme"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "Južno-afričko vreme"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "Zapadno-afričko vreme",
                "standard": "Zapadno-afričko standardno vreme",
                "daylight": "Zapadno-afričko letnje vreme"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Aljaska",
                "standard": "Aljaska, standardno vreme",
                "daylight": "Aljaska, letnje vreme"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Almati vreme",
                "standard": "Almati standardno vreme",
                "daylight": "Almati letnje računanje vremena"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amazon vreme",
                "standard": "Amazon, standardno vreme",
                "daylight": "Amazon, letnje vreme"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Severnoameričko centralno vreme",
                "standard": "Severnoameričko centralno standardno vreme",
                "daylight": "Severnoameričko centralno letnje vreme"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Severnoameričko istočno vreme",
                "standard": "Severnoameričko istočno standardno vreme",
                "daylight": "Severnoameričko istočno letnje vreme"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Severnoameričko planinsko vreme",
                "standard": "Severnoameričko planinsko standardno vreme",
                "daylight": "Severnoameričko planinsko letnje vreme"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Severnoameričko pacifičko vreme",
                "standard": "Severnoameričko pacifičko standardno vreme",
                "daylight": "Severnoameričko pacifičko letnje vreme"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Anadir vreme",
                "standard": "Anadir standardno vreme",
                "daylight": "Anadir letnje računanje vremena"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apija vreme",
                "standard": "Apija, standardno vreme",
                "daylight": "Apija, letnje vreme"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Akvatau vreme",
                "standard": "Akvatau standardno vreme",
                "daylight": "Akvatau letnje računanje vremena"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Akutobe vreme",
                "standard": "Akutobe standardno vreme",
                "daylight": "Akutobe letnje računanje vremena"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Arabijsko vreme",
                "standard": "Arabijsko standardno vreme",
                "daylight": "Arabijsko letnje vreme"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentina vreme",
                "standard": "Argentina, standardno vreme",
                "daylight": "Argentina, letnje vreme"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Zapadna Argentina vreme",
                "standard": "Zapadna Argentina, standardno vreme",
                "daylight": "Zapadna Argentina, letnje vreme"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Jermenija vreme",
                "standard": "Jermenija, standardno vreme",
                "daylight": "Jermenija, letnje vreme"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Atlantsko vreme",
                "standard": "Atlantsko standardno vreme",
                "daylight": "Atlantsko letnje vreme"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Australijsko centralno vreme",
                "standard": "Australijsko centralno standardno vreme",
                "daylight": "Australijsko centralno letnje vreme"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Australijsko centralno zapadno vreme",
                "standard": "Australijsko centralno zapadno standardno vreme",
                "daylight": "Australijsko centralno zapadno letnje vreme"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Australijsko istočno vreme",
                "standard": "Australijsko istočno standardno vreme",
                "daylight": "Australijsko istočno letnje vreme"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "Australijsko zapadno vreme",
                "standard": "Australijsko zapadno standardno vreme",
                "daylight": "Australijsko zapadno letnje vreme"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Azerbejdžan vreme",
                "standard": "Azerbejdžan, standardno vreme",
                "daylight": "Azerbejdžan, letnje vreme"
              }
            },
            "Azores": {
              "long": {
                "generic": "Azori vreme",
                "standard": "Azori, standardno vreme",
                "daylight": "Azori, letnje vreme"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bangladeš vreme",
                "standard": "Bangladeš, standardno vreme",
                "daylight": "Bangladeš, letnje vreme"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Butan vreme"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Bolivija vreme"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brazilija vreme",
                "standard": "Brazilija, standardno vreme",
                "daylight": "Brazilija, letnje vreme"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Brunej Darusalum vreme"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Zelenortska Ostrva vreme",
                "standard": "Zelenortska Ostrva, standardno vreme",
                "daylight": "Zelenortska Ostrva, letnje vreme"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Čamoro vreme"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Čatam vreme",
                "standard": "Čatam, standardno vreme",
                "daylight": "Čatam, letnje vreme"
              }
            },
            "Chile": {
              "long": {
                "generic": "Čile vreme",
                "standard": "Čile, standardno vreme",
                "daylight": "Čile, letnje vreme"
              }
            },
            "China": {
              "long": {
                "generic": "Kina vreme",
                "standard": "Kinesko standardno vreme",
                "daylight": "Kina, letnje vreme"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Čojbalsan vreme",
                "standard": "Čojbalsan, standardno vreme",
                "daylight": "Čojbalsan, letnje vreme"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Božićno ostrvo vreme"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Kokos (Keling) Ostrva vreme"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Kolumbija vreme",
                "standard": "Kolumbija, standardno vreme",
                "daylight": "Kolumbija, letnje vreme"
              }
            },
            "Cook": {
              "long": {
                "generic": "Kukova ostrva vreme",
                "standard": "Kukova ostrva, standardno vreme",
                "daylight": "Kukova ostrva, polu-letnje vreme"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Kuba",
                "standard": "Kuba, standardno vreme",
                "daylight": "Kuba, letnje vreme"
              }
            },
            "Davis": {
              "long": {
                "standard": "Dejvis vreme"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Dimon d’Urvil vreme"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Istočni timor vreme"
              }
            },
            "Easter": {
              "long": {
                "generic": "Uskršnja ostrva vreme",
                "standard": "Uskršnja ostrva, standardno vreme",
                "daylight": "Uskršnja ostrva, letnje vreme"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ekvador vreme"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Srednjeevropsko vreme",
                "standard": "Srednjeevropsko standardno vreme",
                "daylight": "Srednjeevropsko letnje vreme"
              },
              "short": {
                "generic": "CET",
                "standard": "CET",
                "daylight": "CEST"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Istočnoevropsko vreme",
                "standard": "Istočnoevropsko standardno vreme",
                "daylight": "Istočnoevropsko letnje vreme"
              },
              "short": {
                "generic": "EET",
                "standard": "EET",
                "daylight": "EEST"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Dalji istok Evrope"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "Zapadnoevropsko vreme",
                "standard": "Zapadnoevropsko standardno vreme",
                "daylight": "Zapadnoevropsko letnje vreme"
              },
              "short": {
                "generic": "WET",
                "standard": "WET",
                "daylight": "WEST"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Folklandska Ostrva vreme",
                "standard": "Folklandska Ostrva, standardno vreme",
                "daylight": "Folklandska Ostrva, letnje vreme"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fidži vreme",
                "standard": "Fidži, standardno vreme",
                "daylight": "Fidži, letnje vreme"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Francuska Gvajana vreme"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Francusko južno i antarktičko vreme"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapagos vreme"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambije vreme"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Gruzija vreme",
                "standard": "Gruzija, standardno vreme",
                "daylight": "Gruzija, letnje vreme"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Gilbert ostrva vreme"
              }
            },
            "GMT": {
              "long": {
                "standard": "Srednje vreme po Griniču"
              },
              "short": {
                "standard": "GMT"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Istočni Grenland",
                "standard": "Istočni Grenland, standardno vreme",
                "daylight": "Istočni Grenland, letnje vreme"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "Zapadni Grenland",
                "standard": "Zapadni Grenland, standardno vreme",
                "daylight": "Zapadni Grenland, letnje vreme"
              }
            },
            "Guam": {
              "long": {
                "standard": "Guam standardno vreme"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Zalivsko vreme"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Gvajana vreme"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Havajsko-aleutsko vreme",
                "standard": "Havajsko-aleutsko standardno vreme",
                "daylight": "Havajsko-aleutsko letnje vreme"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hong Kong vreme",
                "standard": "Hong Kong, standardno vreme",
                "daylight": "Hong Kong, letnje vreme"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Hovd vreme",
                "standard": "Hovd, standardno vreme",
                "daylight": "Hovd, letnje vreme"
              }
            },
            "India": {
              "long": {
                "standard": "Indijsko standardno vreme"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Indijsko okeansko vreme"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Indokina vreme"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Centralno-indonezijsko vreme"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Istočno-indonezijsko vreme"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Zapadno-indonezijsko vreme"
              }
            },
            "Iran": {
              "long": {
                "generic": "Iran vreme",
                "standard": "Iran, standardno vreme",
                "daylight": "Iran, letnje vreme"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkuck vreme",
                "standard": "Irkuck, standardno vreme",
                "daylight": "Irkuck, letnje vreme"
              }
            },
            "Israel": {
              "long": {
                "generic": "Izraelsko vreme",
                "standard": "Izraelsko standardno vreme",
                "daylight": "Izraelsko letnje vreme"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japansko vreme",
                "standard": "Japansko standardno vreme",
                "daylight": "Japansko letnje vreme"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Petropavlovsko-kamčatsko vreme",
                "standard": "Petropavlovsko-kamčatsko standardno vreme",
                "daylight": "Petropavlovsko-kamčatsko letnje računanje vremena"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Istočno-kazahstansko vreme"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "Zapadno-kazahstansko vreme"
              }
            },
            "Korea": {
              "long": {
                "generic": "Korejsko vreme",
                "standard": "Korejsko standardno vreme",
                "daylight": "Korejsko letnje vreme"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Košre vreme"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnojarsk vreme",
                "standard": "Krasnojarsk, standardno vreme",
                "daylight": "Krasnojarsk, letnje vreme"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kirgistan vreme"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Šri Lanka vreme"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Ostrva Lajn vreme"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Lord Hov vreme",
                "standard": "Lord Hov, standardno vreme",
                "daylight": "Lord Hov, letnje vreme"
              }
            },
            "Macau": {
              "long": {
                "generic": "Makao vreme",
                "standard": "Makao standardno vreme",
                "daylight": "Makao letnje računanje vremena"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Ostrvo Makveri vreme"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadan vreme",
                "standard": "Magadan, standardno vreme",
                "daylight": "Magadan, letnje vreme"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Malezija vreme"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maldivi vreme"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Markiz vreme"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Maršalska Ostrva vreme"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauricijus vreme",
                "standard": "Mauricijus, standardno vreme",
                "daylight": "Mauricijus, letnje vreme"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Moson vreme"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Severozapadni Meksiko",
                "standard": "Severozapadni Meksiko, standardno vreme",
                "daylight": "Severozapadni Meksiko, letnje vreme"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Meksički Pacifik",
                "standard": "Meksički Pacifik, standardno vreme",
                "daylight": "Meksički Pacifik, letnje vreme"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ulan Bator vreme",
                "standard": "Ulan Bator, standardno vreme",
                "daylight": "Ulan Bator, letnje vreme"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskva vreme",
                "standard": "Moskva, standardno vreme",
                "daylight": "Moskva, letnje vreme"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Mijanmar vreme"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Nauru vreme"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepal vreme"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Nova Kaledonija vreme",
                "standard": "Nova Kaledonija, standardno vreme",
                "daylight": "Nova Kaledonija, letnje vreme"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Novi Zeland vreme",
                "standard": "Novi Zeland, standardno vreme",
                "daylight": "Novi Zeland, letnje vreme"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Njufaundlend",
                "standard": "Njufaundlend, standardno vreme",
                "daylight": "Njufaundlend, letnje vreme"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niue vreme"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolk Ostrvo vreme"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Fernando de Noronja vreme",
                "standard": "Fernando de Noronja, standardno vreme",
                "daylight": "Fernando de Noronja, letnje vreme"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "Severna Marijanska Ostrva vreme"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Novosibirsk vreme",
                "standard": "Novosibirsk, standardno vreme",
                "daylight": "Novosibirsk, letnje vreme"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omsk vreme",
                "standard": "Omsk, standardno vreme",
                "daylight": "Omsk, letnje vreme"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pakistan vreme",
                "standard": "Pakistan, standardno vreme",
                "daylight": "Pakistan, letnje vreme"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palau vreme"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Papua Nova Gvineja vreme"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paragvaj vreme",
                "standard": "Paragvaj, standardno vreme",
                "daylight": "Paragvaj, letnje vreme"
              }
            },
            "Peru": {
              "long": {
                "generic": "Peru vreme",
                "standard": "Peru, standardno vreme",
                "daylight": "Peru, letnje vreme"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Filipini vreme",
                "standard": "Filipini, standardno vreme",
                "daylight": "Filipini, letnje vreme"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Feniks ostrva vreme"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Sen Pjer i Mikelon",
                "standard": "Sen Pjer i Mikelon, standardno vreme",
                "daylight": "Sen Pjer i Mikelon, letnje vreme"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitkern vreme"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Ponpej vreme"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pjongjanško vreme"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Kizilorda vreme",
                "standard": "Kizilorda standardno vreme",
                "daylight": "Kizilorda letnje računanje vremena"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Reinion vreme"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rotera vreme"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sahalin vreme",
                "standard": "Sahalin, standardno vreme",
                "daylight": "Sahalin, letnje vreme"
              }
            },
            "Samara": {
              "long": {
                "generic": "Samara vreme",
                "standard": "Samara standardno vreme",
                "daylight": "Samara letnje računanje vremena"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samoa vreme",
                "standard": "Samoa, standardno vreme",
                "daylight": "Samoa, letnje vreme"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Sejšeli vreme"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singapur, standardno vreme"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Solomonska Ostrva vreme"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Južna Džordžija vreme"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Surinam vreme"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Šova vreme"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahiti vreme"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Tajpej vreme",
                "standard": "Tajpej, standardno vreme",
                "daylight": "Tajpej, letnje vreme"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tadžikistan vreme"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelau vreme"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tonga vreme",
                "standard": "Tonga, standardno vreme",
                "daylight": "Tonga, letnje vreme"
              }
            },
            "Truk": {
              "long": {
                "standard": "Čuuk vreme"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Turkmenistan vreme",
                "standard": "Turkmenistan, standardno vreme",
                "daylight": "Turkmenistan, letnje vreme"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvalu vreme"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Urugvaj vreme",
                "standard": "Urugvaj, standardno vreme",
                "daylight": "Urugvaj, letnje vreme"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Uzbekistan vreme",
                "standard": "Uzbekistan, standardno vreme",
                "daylight": "Uzbekistan, letnje vreme"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatu vreme",
                "standard": "Vanuatu, standardno vreme",
                "daylight": "Vanuatu, letnje vreme"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Venecuela vreme"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Vladivostok vreme",
                "standard": "Vladivostok, standardno vreme",
                "daylight": "Vladivostok, letnje vreme"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Volgograd vreme",
                "standard": "Volgograd, standardno vreme",
                "daylight": "Volgograd, letnje vreme"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Vostok vreme"
              }
            },
            "Wake": {
              "long": {
                "standard": "Vejk ostrvo vreme"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Valis i Futuna Ostrva vreme"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Jakutsk vreme",
                "standard": "Jakutsk, standardno vreme",
                "daylight": "Jakutsk, letnje vreme"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Jekaterinburg vreme",
                "standard": "Jekaterinburg, standardno vreme",
                "daylight": "Jekaterinburg, letnje vreme"
              }
            }
          }
        }
      }
    }
  }
}
