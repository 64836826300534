import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { StorageKeyConstants } from '@goatsports/shared/util';
import { TranslocoService } from '@jsverse/transloco';
import { filter, switchMap, tap } from 'rxjs';
import { ApplicationStateService } from './shared/services/application-state.service';
import { RouteConfigLoadStart, Router } from '@angular/router';
import { CurrentUserService } from './shared/services/current-user.service';
import { RoleType } from '@goatsports/core/data-access';
import { RouteConstants } from './shared/constants/route.constant';
import {
  DropDownListComponent,
  VirtualScroll,
} from '@syncfusion/ej2-angular-dropdowns';
import { toSignal } from '@angular/core/rxjs-interop';

DropDownListComponent.Inject(VirtualScroll);

@Component({
  selector: 'goatsports-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  apiLoaded = false;

  routeChanges = toSignal(
    this.router.events.pipe(
      filter(
        (x) =>
          x instanceof RouteConfigLoadStart &&
          x.route.path === RouteConstants.DASHBOARD_ROUTE,
      ),
      tap((x) => {
        (x as RouteConfigLoadStart).route.loadChildren = async () => {
          switch (this.currentUserService.currentUserRole) {
            case RoleType.Coach: {
              const m_1 = await import('./dashboard/dashboard.module');
              return m_1.DashboardModule;
            }
            case RoleType.OrganizationAdmin: {
              const m2 = await import(
                './organization-dashboard/organization-dashboard.module'
              );
              return m2.OrganizationDashboardModule;
            }
            case RoleType.SuperAdmin: {
              const m3 = await import(
                './global-dashboard/global-dashboard.module'
              );
              return m3.GlobalDashboardModule;
            }
            default: {
              const m = await import('./dashboard/dashboard.module');
              return m.DashboardModule;
            }
          }
        };
      }),
    ),
  );

  languageChanges = toSignal(
    this.translocoService.langChanges$.pipe(
      switchMap((language) =>
        this.translocoService.load(language).pipe(
          tap(() => {
            this.applicationStateService.languageChanged = language;
          }),
        ),
      ),
    ),
  );

  constructor(
    private translocoService: TranslocoService,
    private applicationStateService: ApplicationStateService,
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {}

  async ngOnInit() {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }

    const language = localStorage.getItem(StorageKeyConstants.LANGUAGE_KEY);

    if (language) {
      setCulture(language);
    }

    L10n.load({
      en: {
        grid: {
          EmptyRecord: 'No records to display',
          GroupDropArea: 'Drag a column header here to group its column',
          UnGroup: 'Click here to ungroup',
          EmptyDataSourceError:
            'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
          Item: 'item',
          Items: 'items',
        },
        pager: {
          currentPageInfo: '{0} of {1} pages',
          totalItemsInfo: '({0} items)',
          totalItemInfo: '({0} item)',
          firstPageTooltip: 'Go to first page',
          lastPageTooltip: 'Go to last page',
          nextPageTooltip: 'Go to next page',
          previousPageTooltip: 'Go to previous page',
          nextPagerTooltip: 'Go to next pager items',
          previousPagerTooltip: 'Go to previous pager items',
          pagerDropDown: 'Items per page',
          pagerAllDropDown: 'Items',
        },
        'multi-select': {
          noRecordsTemplate: 'No Records Found',
          actionFailureTemplate: 'The Request Failed',
          overflowCountTemplate: '+${count} more..',
          totalCountTemplate: '${count} selected',
        },
        schedule: {
          day: 'Day',
          week: 'Week',
          workWeek: 'Work Week',
          month: 'Month',
          year: 'Year',
          today: 'Today',
        },
        datepicker: {
          today: 'Today',
        },
        calendar: {
          today: 'Today',
        },
        dropdowns: {
          noRecordsTemplate: 'No Records Found',
          actionFailureTemplate: 'The Request Failed',
          emptyResultText: 'No Results Found',
        },
        dialog: {
          close: 'Close',
        },
      },
      sv: {
        grid: {
          EmptyRecord: 'Inga poster att visa',
          GroupDropArea: 'Dra en kolumnrubrik hit för att gruppera kolumnen',
          UnGroup: 'Klicka här för att avgruppera',
          EmptyDataSourceError:
            'Datakällan kan inte vara tom vid initial hämtning för att kolumnerna i datakällan används för att automatiskt genererande data kolumner',
          Item: 'rad',
          Items: 'rader',
        },
        pager: {
          currentPageInfo: '{0} av {1} sidor',
          totalItemsInfo: '({0} rader)',
          totalItemInfo: '({0} rad)',
          firstPageTooltip: 'Gå till första sidan',
          lastPageTooltip: 'Gå till sista sidan',
          nextPageTooltip: 'Gå till nästa sida',
          previousPageTooltip: 'Gå till föregående sida',
          nextPagerTooltip: 'Gå till nästa sidas rader',
          previousPagerTooltip: 'Gå till föregående sidas rader',
          pagerDropDown: 'Rader per sida',
          pagerAllDropDown: 'Rader',
        },
        'multi-select': {
          noRecordsTemplate: 'Inga uppgifter funna',
          actionFailureTemplate: 'Action Failure Model',
          overflowCountTemplate: '+${count} mer..',
          totalCountTemplate: '${count} valda',
        },
        schedule: {
          day: 'Dag',
          week: 'Vecka',
          workWeek: 'Arbetsvecka',
          month: 'Månad',
          year: 'År',
          today: 'Idag',
        },
        datepicker: {
          today: 'Idag',
        },
        calendar: {
          today: 'Idag',
        },
        dropdowns: {
          noRecordsTemplate: 'Inga uppgifter funna',
          actionFailureTemplate: 'Begäran misslyckades',
          emptyResultText: 'Inga förslag',
        },
        dialog: {
          close: 'Stäng',
        },
      },
      'sr-Latn': {
        grid: {
          EmptyRecord: 'Nema zapisa za prikaz',
          GroupDropArea:
            'Prevuci zaglavlje kolone ovde da grupišeš njegovu kolonu',
          UnGroup: 'Razgrupiši',
          EmptyDataSourceError:
            'DataSource ne sme biti prazan pri inicijalnom učitavanju jer se kolone generišu iz dataSource u AutoGenerate Column Grid',
          Item: 'stavka',
          Items: 'stavke',
        },
        pager: {
          currentPageInfo: 'Strana {0} od {1}',
          totalItemsInfo: '(Broj stavki: {0})',
          totalItemInfo: '(Red: {0})',
          firstPageTooltip: 'Idi na prvu stranicu',
          lastPageTooltip: 'Idi na poslednju stranicu',
          nextPageTooltip: 'Idi na sledeću stranicu',
          previousPageTooltip: 'Idi na prethodnu stranicu',
          nextPagerTooltip: 'Vidi stavke na sledećoj stranici',
          previousPagerTooltip: 'Vidi stavke na prethodnoj stranici',
          pagerDropDown: 'Stavke po stranici',
          pagerAllDropDown: 'Stavke',
        },
        'multi-select': {
          noRecordsTemplate: 'Nema pronađenih zapisa',
          actionFailureTemplate: 'Zahtev nije uspešan',
          overflowCountTemplate: '+${count} više..',
          totalCountTemplate: '${count} odabrano',
        },
        schedule: {
          day: 'Dan',
          week: 'Nedelja',
          workWeek: 'Radna nedelja',
          month: 'Mesec',
          year: 'Godina',
          today: 'Danas',
        },
        datepicker: {
          today: 'Danas',
        },
        calendar: {
          today: 'Danas',
        },
        dropdowns: {
          noRecordsTemplate: 'Nema pronađenih zapisa',
          actionFailureTemplate: 'Zahtev nije uspešan',
          emptyResultText: 'Nema pronađenih rezultata',
        },
        dialog: {
          close: 'Zatvori',
        },
      },
      is: {
        grid: {
          EmptyRecord: 'Engar skrár til að sýna',
          GroupDropArea: 'Dragðu dálkhaus hingað til að flokka dálkinn hans',
          UnGroup: 'Smelltu hér til að taka upp hóp',
          EmptyDataSourceError:
            'DataSource má ekki vera tómt við upphafshleðslu þar sem dálkar eru búnir til úr dataSource í AutoGenerate Column Grid',
          Item: 'röð',
          Items: 'raðir',
        },
        pager: {
          currentPageInfo: '{0} af {1} síðum',
          totalItemsInfo: '({0} röð)',
          totalItemInfo: '({0} raðir)',
          firstPageTooltip: 'Farðu á fyrstu síðu',
          lastPageTooltip: 'Farðu á síðustu síðu',
          nextPageTooltip: 'Farðu á næstu síðu',
          previousPageTooltip: 'Farðu á fyrri síðu',
          nextPagerTooltip: 'Farðu í næstu röð í síðuboði',
          previousPagerTooltip: 'Farðu í fyrri boðsendingaratriði',
          pagerDropDown: 'raðir á síðu',
        },
        'multi-select': {
          noRecordsTemplate: 'Engar færslur fundust',
          actionFailureTemplate: 'Beiðni mistókst',
          overflowCountTemplate: '+${count} í viðbót..',
          totalCountTemplate: '${count} valið',
        },
        schedule: {
          day: 'Dagur',
          week: 'Vika',
          workWeek: 'Vinnuvika',
          month: 'Mánuður',
          year: 'Ár',
          today: 'Í dag',
        },
        datepicker: {
          today: 'Í dag',
        },
        calendar: {
          today: 'Í dag',
        },
        dropdowns: {
          noRecordsTemplate: 'Engar færslur fundust',
          actionFailureTemplate: 'Beiðni mistókst',
          emptyResultText: 'Engin útkoma fannst',
        },
        dialog: {
          close: 'Loka',
        },
      },
    });
  }
}
