import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { StorageKeyConstants } from '@goatsports/shared/util';
import { ToastService } from '../services/toast.service';
import { ErrorService } from '../services/error.service';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
  private readonly VIMEO_URL = 'https://vimeo.com/api/v2/video';

  private readonly excludedEndpoints: string[] = [
    `${environment.coreApiUrl}/QuizEvents`,
    `${environment.coreApiUrl}/HabitEvents`,
    `${environment.coreApiUrl}/ReflectionEvents`,
    `${environment.coreApiUrl}/UserHabitEvents`,
    `${environment.coreApiUrl}/UserReflectionEvents`,
    `${environment.coreApiUrl}/UserQuizzes`,
  ];

  constructor(
    private errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const language =
      localStorage.getItem(StorageKeyConstants.LANGUAGE_KEY) ?? 'en';

    const isVimeoUrl = request.url.includes(this.VIMEO_URL);

    if (!isVimeoUrl) {
      request = request.clone({
        headers: request.headers.set('language', language),
      });
    }

    if (
      (request.method === 'POST' || request.method === 'PUT') &&
      this.isExcludedEndpoint(request.url)
    ) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap(() => this.toastService.hideAllToasts()),
      catchError((error: HttpErrorResponse) => {
        if (isVimeoUrl) return throwError(() => error);

        if (request.method === 'GET') {
          switch (error.status) {
            case 0:
            case 401:
            case 403:
              // this.authenticationService.logout();
              break;
            case 400: {
              const validationErrors = [];
              for (const value of Object.values(error.error.errors)) {
                if (Array.isArray(value)) {
                  validationErrors.push(...value);
                } else {
                  validationErrors.push(value);
                }
              }
              this.errorService.publishValidationErrors(validationErrors);
              break;
            }

            case 404:
              this.errorService.publishErrorMessage('Resource not found');
              break;

            case 500:
              this.errorService.publishErrorMessage(
                'An error occurred while processing your request',
              );
              break;

            default:
              this.errorService.publishErrorMessage(
                'Server is not reachable, please try again',
              );
              break;
          }
        } else {
          if (error.status === 401 || error.status === 403) {
            this.authenticationService.logout();
          } else {
            this.toastService.showErrorToast(error);
          }
        }
        return throwError(() => error);
      }),
    );
  }
  private isExcludedEndpoint(url: string): boolean {
    return this.excludedEndpoints.some((endpoint) => url.includes(endpoint));
  }
}
