{
  "supplemental": {
    "version": {
      "_unicodeVersion": "12.1.0",
      "_cldrVersion": "36"
    },
    "numberingSystems": {
      "adlm": {
        "_digits": "𞥐𞥑𞥒𞥓𞥔𞥕𞥖𞥗𞥘𞥙",
        "_type": "numeric"
      },
      "ahom": {
        "_digits": "𑜰𑜱𑜲𑜳𑜴𑜵𑜶𑜷𑜸𑜹",
        "_type": "numeric"
      },
      "arab": {
        "_digits": "٠١٢٣٤٥٦٧٨٩",
        "_type": "numeric"
      },
      "arabext": {
        "_digits": "۰۱۲۳۴۵۶۷۸۹",
        "_type": "numeric"
      },
      "armn": {
        "_rules": "armenian-upper",
        "_type": "algorithmic"
      },
      "armnlow": {
        "_rules": "armenian-lower",
        "_type": "algorithmic"
      },
      "bali": {
        "_digits": "᭐᭑᭒᭓᭔᭕᭖᭗᭘᭙",
        "_type": "numeric"
      },
      "beng": {
        "_digits": "০১২৩৪৫৬৭৮৯",
        "_type": "numeric"
      },
      "bhks": {
        "_digits": "𑱐𑱑𑱒𑱓𑱔𑱕𑱖𑱗𑱘𑱙",
        "_type": "numeric"
      },
      "brah": {
        "_digits": "𑁦𑁧𑁨𑁩𑁪𑁫𑁬𑁭𑁮𑁯",
        "_type": "numeric"
      },
      "cakm": {
        "_digits": "𑄶𑄷𑄸𑄹𑄺𑄻𑄼𑄽𑄾𑄿",
        "_type": "numeric"
      },
      "cham": {
        "_digits": "꩐꩑꩒꩓꩔꩕꩖꩗꩘꩙",
        "_type": "numeric"
      },
      "cyrl": {
        "_rules": "cyrillic-lower",
        "_type": "algorithmic"
      },
      "deva": {
        "_digits": "०१२३४५६७८९",
        "_type": "numeric"
      },
      "ethi": {
        "_rules": "ethiopic",
        "_type": "algorithmic"
      },
      "fullwide": {
        "_digits": "０１２３４５６７８９",
        "_type": "numeric"
      },
      "geor": {
        "_rules": "georgian",
        "_type": "algorithmic"
      },
      "gong": {
        "_digits": "𑶠𑶡𑶢𑶣𑶤𑶥𑶦𑶧𑶨𑶩",
        "_type": "numeric"
      },
      "gonm": {
        "_digits": "𑵐𑵑𑵒𑵓𑵔𑵕𑵖𑵗𑵘𑵙",
        "_type": "numeric"
      },
      "grek": {
        "_rules": "greek-upper",
        "_type": "algorithmic"
      },
      "greklow": {
        "_rules": "greek-lower",
        "_type": "algorithmic"
      },
      "gujr": {
        "_digits": "૦૧૨૩૪૫૬૭૮૯",
        "_type": "numeric"
      },
      "guru": {
        "_digits": "੦੧੨੩੪੫੬੭੮੯",
        "_type": "numeric"
      },
      "hanidays": {
        "_rules": "zh/SpelloutRules/spellout-numbering-days",
        "_type": "algorithmic"
      },
      "hanidec": {
        "_digits": "〇一二三四五六七八九",
        "_type": "numeric"
      },
      "hans": {
        "_rules": "zh/SpelloutRules/spellout-cardinal",
        "_type": "algorithmic"
      },
      "hansfin": {
        "_rules": "zh/SpelloutRules/spellout-cardinal-financial",
        "_type": "algorithmic"
      },
      "hant": {
        "_rules": "zh_Hant/SpelloutRules/spellout-cardinal",
        "_type": "algorithmic"
      },
      "hantfin": {
        "_rules": "zh_Hant/SpelloutRules/spellout-cardinal-financial",
        "_type": "algorithmic"
      },
      "hebr": {
        "_rules": "hebrew",
        "_type": "algorithmic"
      },
      "hmng": {
        "_digits": "𖭐𖭑𖭒𖭓𖭔𖭕𖭖𖭗𖭘𖭙",
        "_type": "numeric"
      },
      "hmnp": {
        "_digits": "𞅀𞅁𞅂𞅃𞅄𞅅𞅆𞅇𞅈𞅉",
        "_type": "numeric"
      },
      "java": {
        "_digits": "꧐꧑꧒꧓꧔꧕꧖꧗꧘꧙",
        "_type": "numeric"
      },
      "jpan": {
        "_rules": "ja/SpelloutRules/spellout-cardinal",
        "_type": "algorithmic"
      },
      "jpanfin": {
        "_rules": "ja/SpelloutRules/spellout-cardinal-financial",
        "_type": "algorithmic"
      },
      "jpanyear": {
        "_rules": "ja/SpelloutRules/spellout-numbering-year-latn",
        "_type": "algorithmic"
      },
      "kali": {
        "_digits": "꤀꤁꤂꤃꤄꤅꤆꤇꤈꤉",
        "_type": "numeric"
      },
      "khmr": {
        "_digits": "០១២៣៤៥៦៧៨៩",
        "_type": "numeric"
      },
      "knda": {
        "_digits": "೦೧೨೩೪೫೬೭೮೯",
        "_type": "numeric"
      },
      "lana": {
        "_digits": "᪀᪁᪂᪃᪄᪅᪆᪇᪈᪉",
        "_type": "numeric"
      },
      "lanatham": {
        "_digits": "᪐᪑᪒᪓᪔᪕᪖᪗᪘᪙",
        "_type": "numeric"
      },
      "laoo": {
        "_digits": "໐໑໒໓໔໕໖໗໘໙",
        "_type": "numeric"
      },
      "latn": {
        "_digits": "0123456789",
        "_type": "numeric"
      },
      "lepc": {
        "_digits": "᱀᱁᱂᱃᱄᱅᱆᱇᱈᱉",
        "_type": "numeric"
      },
      "limb": {
        "_digits": "᥆᥇᥈᥉᥊᥋᥌᥍᥎᥏",
        "_type": "numeric"
      },
      "mathbold": {
        "_digits": "𝟎𝟏𝟐𝟑𝟒𝟓𝟔𝟕𝟖𝟗",
        "_type": "numeric"
      },
      "mathdbl": {
        "_digits": "𝟘𝟙𝟚𝟛𝟜𝟝𝟞𝟟𝟠𝟡",
        "_type": "numeric"
      },
      "mathmono": {
        "_digits": "𝟶𝟷𝟸𝟹𝟺𝟻𝟼𝟽𝟾𝟿",
        "_type": "numeric"
      },
      "mathsanb": {
        "_digits": "𝟬𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵",
        "_type": "numeric"
      },
      "mathsans": {
        "_digits": "𝟢𝟣𝟤𝟥𝟦𝟧𝟨𝟩𝟪𝟫",
        "_type": "numeric"
      },
      "mlym": {
        "_digits": "൦൧൨൩൪൫൬൭൮൯",
        "_type": "numeric"
      },
      "modi": {
        "_digits": "𑙐𑙑𑙒𑙓𑙔𑙕𑙖𑙗𑙘𑙙",
        "_type": "numeric"
      },
      "mong": {
        "_digits": "᠐᠑᠒᠓᠔᠕᠖᠗᠘᠙",
        "_type": "numeric"
      },
      "mroo": {
        "_digits": "𖩠𖩡𖩢𖩣𖩤𖩥𖩦𖩧𖩨𖩩",
        "_type": "numeric"
      },
      "mtei": {
        "_digits": "꯰꯱꯲꯳꯴꯵꯶꯷꯸꯹",
        "_type": "numeric"
      },
      "mymr": {
        "_digits": "၀၁၂၃၄၅၆၇၈၉",
        "_type": "numeric"
      },
      "mymrshan": {
        "_digits": "႐႑႒႓႔႕႖႗႘႙",
        "_type": "numeric"
      },
      "mymrtlng": {
        "_digits": "꧰꧱꧲꧳꧴꧵꧶꧷꧸꧹",
        "_type": "numeric"
      },
      "newa": {
        "_digits": "𑑐𑑑𑑒𑑓𑑔𑑕𑑖𑑗𑑘𑑙",
        "_type": "numeric"
      },
      "nkoo": {
        "_digits": "߀߁߂߃߄߅߆߇߈߉",
        "_type": "numeric"
      },
      "olck": {
        "_digits": "᱐᱑᱒᱓᱔᱕᱖᱗᱘᱙",
        "_type": "numeric"
      },
      "orya": {
        "_digits": "୦୧୨୩୪୫୬୭୮୯",
        "_type": "numeric"
      },
      "osma": {
        "_digits": "𐒠𐒡𐒢𐒣𐒤𐒥𐒦𐒧𐒨𐒩",
        "_type": "numeric"
      },
      "rohg": {
        "_digits": "𐴰𐴱𐴲𐴳𐴴𐴵𐴶𐴷𐴸𐴹",
        "_type": "numeric"
      },
      "roman": {
        "_rules": "roman-upper",
        "_type": "algorithmic"
      },
      "romanlow": {
        "_rules": "roman-lower",
        "_type": "algorithmic"
      },
      "saur": {
        "_digits": "꣐꣑꣒꣓꣔꣕꣖꣗꣘꣙",
        "_type": "numeric"
      },
      "shrd": {
        "_digits": "𑇐𑇑𑇒𑇓𑇔𑇕𑇖𑇗𑇘𑇙",
        "_type": "numeric"
      },
      "sind": {
        "_digits": "𑋰𑋱𑋲𑋳𑋴𑋵𑋶𑋷𑋸𑋹",
        "_type": "numeric"
      },
      "sinh": {
        "_digits": "෦෧෨෩෪෫෬෭෮෯",
        "_type": "numeric"
      },
      "sora": {
        "_digits": "𑃰𑃱𑃲𑃳𑃴𑃵𑃶𑃷𑃸𑃹",
        "_type": "numeric"
      },
      "sund": {
        "_digits": "᮰᮱᮲᮳᮴᮵᮶᮷᮸᮹",
        "_type": "numeric"
      },
      "takr": {
        "_digits": "𑛀𑛁𑛂𑛃𑛄𑛅𑛆𑛇𑛈𑛉",
        "_type": "numeric"
      },
      "talu": {
        "_digits": "᧐᧑᧒᧓᧔᧕᧖᧗᧘᧙",
        "_type": "numeric"
      },
      "taml": {
        "_rules": "tamil",
        "_type": "algorithmic"
      },
      "tamldec": {
        "_digits": "௦௧௨௩௪௫௬௭௮௯",
        "_type": "numeric"
      },
      "telu": {
        "_digits": "౦౧౨౩౪౫౬౭౮౯",
        "_type": "numeric"
      },
      "thai": {
        "_digits": "๐๑๒๓๔๕๖๗๘๙",
        "_type": "numeric"
      },
      "tibt": {
        "_digits": "༠༡༢༣༤༥༦༧༨༩",
        "_type": "numeric"
      },
      "tirh": {
        "_digits": "𑓐𑓑𑓒𑓓𑓔𑓕𑓖𑓗𑓘𑓙",
        "_type": "numeric"
      },
      "vaii": {
        "_digits": "꘠꘡꘢꘣꘤꘥꘦꘧꘨꘩",
        "_type": "numeric"
      },
      "wara": {
        "_digits": "𑣠𑣡𑣢𑣣𑣤𑣥𑣦𑣧𑣨𑣩",
        "_type": "numeric"
      },
      "wcho": {
        "_digits": "𞋰𞋱𞋲𞋳𞋴𞋵𞋶𞋷𞋸𞋹",
        "_type": "numeric"
      }
    }
  }
}
