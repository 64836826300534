{
  "supplemental": {
    "version": {
      "_unicodeVersion": "12.1.0",
      "_cldrVersion": "36"
    },
    "weekData": {
      "minDays": {
        "001": "1",
        "AD": "4",
        "AN": "4",
        "AT": "4",
        "AX": "4",
        "BE": "4",
        "BG": "4",
        "CH": "4",
        "CZ": "4",
        "DE": "4",
        "DK": "4",
        "EE": "4",
        "ES": "4",
        "FI": "4",
        "FJ": "4",
        "FO": "4",
        "FR": "4",
        "GB": "4",
        "GF": "4",
        "GG": "4",
        "GI": "4",
        "GP": "4",
        "GR": "4",
        "GU": "1",
        "HU": "4",
        "IE": "4",
        "IM": "4",
        "IS": "4",
        "IT": "4",
        "JE": "4",
        "LI": "4",
        "LT": "4",
        "LU": "4",
        "MC": "4",
        "MQ": "4",
        "NL": "4",
        "NO": "4",
        "PL": "4",
        "PT": "4",
        "RE": "4",
        "RU": "4",
        "SE": "4",
        "SJ": "4",
        "SK": "4",
        "SM": "4",
        "UM": "1",
        "US": "1",
        "VA": "4",
        "VI": "1"
      },
      "firstDay": {
        "001": "mon",
        "AD": "mon",
        "AE": "sat",
        "AF": "sat",
        "AG": "sun",
        "AI": "mon",
        "AL": "mon",
        "AM": "mon",
        "AN": "mon",
        "AR": "mon",
        "AS": "sun",
        "AT": "mon",
        "AU": "sun",
        "AX": "mon",
        "AZ": "mon",
        "BA": "mon",
        "BD": "sun",
        "BE": "mon",
        "BG": "mon",
        "BH": "sat",
        "BM": "mon",
        "BN": "mon",
        "BR": "sun",
        "BS": "sun",
        "BT": "sun",
        "BW": "sun",
        "BY": "mon",
        "BZ": "sun",
        "CA": "sun",
        "CH": "mon",
        "CL": "mon",
        "CM": "mon",
        "CN": "sun",
        "CO": "sun",
        "CR": "mon",
        "CY": "mon",
        "CZ": "mon",
        "DE": "mon",
        "DJ": "sat",
        "DK": "mon",
        "DM": "sun",
        "DO": "sun",
        "DZ": "sat",
        "EC": "mon",
        "EE": "mon",
        "EG": "sat",
        "ES": "mon",
        "ET": "sun",
        "FI": "mon",
        "FJ": "mon",
        "FO": "mon",
        "FR": "mon",
        "GB": "mon",
        "GB-alt-variant": "sun",
        "GE": "mon",
        "GF": "mon",
        "GP": "mon",
        "GR": "mon",
        "GT": "sun",
        "GU": "sun",
        "HK": "sun",
        "HN": "sun",
        "HR": "mon",
        "HU": "mon",
        "ID": "sun",
        "IE": "mon",
        "IL": "sun",
        "IN": "sun",
        "IQ": "sat",
        "IR": "sat",
        "IS": "mon",
        "IT": "mon",
        "JM": "sun",
        "JO": "sat",
        "JP": "sun",
        "KE": "sun",
        "KG": "mon",
        "KH": "sun",
        "KR": "sun",
        "KW": "sat",
        "KZ": "mon",
        "LA": "sun",
        "LB": "mon",
        "LI": "mon",
        "LK": "mon",
        "LT": "mon",
        "LU": "mon",
        "LV": "mon",
        "LY": "sat",
        "MC": "mon",
        "MD": "mon",
        "ME": "mon",
        "MH": "sun",
        "MK": "mon",
        "MM": "sun",
        "MN": "mon",
        "MO": "sun",
        "MQ": "mon",
        "MT": "sun",
        "MV": "fri",
        "MX": "sun",
        "MY": "mon",
        "MZ": "sun",
        "NI": "sun",
        "NL": "mon",
        "NO": "mon",
        "NP": "sun",
        "NZ": "mon",
        "OM": "sat",
        "PA": "sun",
        "PE": "sun",
        "PH": "sun",
        "PK": "sun",
        "PL": "mon",
        "PR": "sun",
        "PT": "sun",
        "PY": "sun",
        "QA": "sat",
        "RE": "mon",
        "RO": "mon",
        "RS": "mon",
        "RU": "mon",
        "SA": "sun",
        "SD": "sat",
        "SE": "mon",
        "SG": "sun",
        "SI": "mon",
        "SK": "mon",
        "SM": "mon",
        "SV": "sun",
        "SY": "sat",
        "TH": "sun",
        "TJ": "mon",
        "TM": "mon",
        "TR": "mon",
        "TT": "sun",
        "TW": "sun",
        "UA": "mon",
        "UM": "sun",
        "US": "sun",
        "UY": "mon",
        "UZ": "mon",
        "VA": "mon",
        "VE": "sun",
        "VI": "sun",
        "VN": "mon",
        "WS": "sun",
        "XK": "mon",
        "YE": "sun",
        "ZA": "sun",
        "ZW": "sun"
      },
      "weekendStart": {
        "001": "sat",
        "AE": "fri",
        "AF": "thu",
        "BH": "fri",
        "DZ": "fri",
        "EG": "fri",
        "IL": "fri",
        "IN": "sun",
        "IQ": "fri",
        "IR": "fri",
        "JO": "fri",
        "KW": "fri",
        "LY": "fri",
        "OM": "fri",
        "QA": "fri",
        "SA": "fri",
        "SD": "fri",
        "SY": "fri",
        "UG": "sun",
        "YE": "fri"
      },
      "weekendEnd": {
        "001": "sun",
        "AE": "sat",
        "AF": "fri",
        "BH": "sat",
        "DZ": "sat",
        "EG": "sat",
        "IL": "sat",
        "IQ": "sat",
        "IR": "fri",
        "JO": "sat",
        "KW": "sat",
        "LY": "sat",
        "OM": "sat",
        "QA": "sat",
        "SA": "sat",
        "SD": "sat",
        "SY": "sat",
        "YE": "sat"
      },
      "af": {
        "_ordering": "weekOfDate weekOfInterval weekOfMonth"
      },
      "am az bs cs cy da el et hi ky lt mk sk ta th": {
        "_ordering": "weekOfYear weekOfMonth"
      },
      "ar fil gu hu hy id kk ko": {
        "_ordering": "weekOfMonth"
      },
      "be ro ru": {
        "_ordering": "weekOfInterval weekOfMonth"
      },
      "bg de iw pt ur zh": {
        "_ordering": "weekOfDate weekOfMonth weekOfInterval"
      },
      "ca es fr gl": {
        "_ordering": "weekOfDate"
      },
      "en bn ja ka": {
        "_ordering": "weekOfDate weekOfMonth"
      },
      "eu": {
        "_ordering": "weekOfMonth weekOfDate"
      },
      "fa hr it lv pl si sr uk uz": {
        "_ordering": "weekOfMonth weekOfInterval"
      },
      "fi zh-TW": {
        "_ordering": "weekOfYear weekOfDate weekOfMonth"
      },
      "is mn no sv vi": {
        "_ordering": "weekOfYear weekOfMonth weekOfInterval"
      },
      "km mr": {
        "_ordering": "weekOfMonth weekOfYear"
      },
      "kn ml pa": {
        "_ordering": "weekOfMonth weekOfDate weekOfYear"
      },
      "lo sq": {
        "_ordering": "weekOfMonth weekOfInterval weekOfDate weekOfYear"
      },
      "ms tr": {
        "_ordering": "weekOfMonth weekOfYear weekOfInterval weekOfDate"
      },
      "nl": {
        "_ordering": "weekOfDate weekOfYear weekOfMonth"
      },
      "sl": {
        "_ordering": "weekOfInterval"
      },
      "sw te": {
        "_ordering": "weekOfMonth weekOfInterval weekOfYear"
      },
      "und": {
        "_ordering": "weekOfYear"
      },
      "zu": {
        "_ordering": "weekOfYear weekOfInterval"
      }
    }
  }
}
